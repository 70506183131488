<template>
  <section class="layout layout--bkg-2 layout--single">
    <div class="inner layout__inner">
      <div class="layout__main">
        <div class="account-select">
          <div class="account-select__welcome">
            <img src="@/assets/icons/rocket-dark.svg" alt="logo" />
            <h1 class="account-select__heading">
              Welcome back, {{ userName }}.
            </h1>
          </div>
          <div class="instance-select">
            <template v-if="instances && instances.length">
              <h2 class="account-select__sub-heading">
                Choose an instance to begin
              </h2>
              <div v-for="instance in instances" :key="instance">
                <Chip
                  class="instance instance-select__instance size-l"
                  data-qa-locator="product-line-instance-button"
                  :data-instance-name="instance"
                  :selected="instance === selectedInstance"
                  @click="selectInstance(instance)"
                >
                  {{ instance }}
                </Chip>
              </div>
            </template>
            <h2 v-else class="account-select__sub-heading">
              You don't have any instances yet.
            </h2>
          </div>

          <div v-if="selectedInstance">
            <h2 class="account-select__sub-heading">
              Select agency for {{ selectedInstance }}
            </h2>
            <ul class="account-select__agency-list">
              <AgencyCard
                v-for="plrn in plrns"
                :key="plrn.agency"
                :agency-ref="plrn.agency.toLowerCase()"
                :title="plrn.agencyName"
                data-qa-locator="product-line-agency-button"
                :data-instance-name="selectedInstance"
                :data-agency-ref="plrn.agency"
                @click="selectPlrn(plrn)"
              />
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'

import { resetCatalog } from '@/application/getProductDetails'
import AgencyCard from '@/components/legacy/AgencyCard.vue'
import Chip from '@/components/legacy/Chip.vue'
import useErrorModal from '@/composables/useErrorModal'
import { isValidInstance } from '@/config'
import { useAuth0 } from '@/lib/auth0/auth0-vue-proxy'
import { useBasketStore } from '@/lib/store/basket/useBasket'
import { useQuoteAndBuy } from '@/lib/store/quoteAndBuy/useQuoteAndBuy'
import { generateAgencyName } from '@/lib/utils'
import { setPlrn } from '@/plrn'

const auth = useAuth0()
const quoteAndBuyStore = useQuoteAndBuy()
const basket = useBasketStore()
const selectedInstance = ref('')

interface PLRN {
  value: string
  agency: string
  agencyName: string
}

interface PLRNMap {
  [key: string]: PLRN[]
}

const plrnsByInstance = computed(() => {
  const plrns = auth?.user?.value?.['https://policies.io/plrns'] ?? []
  const productLinesByInstance: PLRNMap = {}
  const sortedPlrns = [...plrns].sort()

  for (const plrn of sortedPlrns) {
    // plnr has the format "plrn:stagpolicies.io:BBM-SE:*"
    const [, instance, agency] = plrn.split(':')

    productLinesByInstance[instance] ??= []
    productLinesByInstance[instance].push({
      value: plrn,
      agency: agency,
      agencyName: generateAgencyName(agency),
    })
  }

  // Sort the product lines within each instance.
  for (const productLines of Object.values(productLinesByInstance)) {
    productLines.sort((a, b) => (a.agencyName < b.agencyName ? 1 : -1))
  }

  return productLinesByInstance
})

const userName = computed(() => auth?.user?.value?.nickname)
const instances = computed(() =>
  Object.keys(plrnsByInstance.value).filter((instance) =>
    isValidInstance(instance)
  )
)
const plrns = computed(() => plrnsByInstance.value[selectedInstance.value])

const selectInstance = (value: string) => {
  selectedInstance.value = value
}
const selectPlrn = (plrn: PLRN): void => {
  try {
    setPlrn(plrn.value)

    quoteAndBuyStore.destroyJourney()
    basket.resetState()
    resetCatalog()
    window.location.assign('/')
  } catch {
    const { showError } = useErrorModal()

    showError(new Error(`Could not change agency to ${plrn.agencyName}`))
  }
}
</script>

<style lang="scss" scoped>
.account-select {
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-template-rows: min-content auto;
  grid-gap: 50px 30px;

  &__heading {
    font-size: 36px;
    margin-bottom: 24px;
  }

  &__sub-heading {
    font-size: 24px;
    margin-bottom: 30px;
    text-align: center;
  }

  &__agency-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: -15px -15px;
  }
}

.account-select__welcome {
  grid-column: span 2;
  text-align: center;
}

.instance-select {
  margin: 0 auto;
  text-align: center;

  &__instance {
    margin-bottom: 10px;
  }
}

.instance {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);

  &:hover {
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.3);
  }
}
</style>
