import { ProductLineConfig } from '@/@types/Config'
import deepFreeze from '@/lib/utils/deepFreeze'

const productLineConfig: ProductLineConfig = {
  claims: {
    minWarningTimespan: {
      duration: 'P14D',
      format: 'days',
    },
    approveLimitPermissionPrefix: 'claims:insurance_gbp:',
    algoliaVetsConfig: {
      api_key: '2ced87292f3eb210b60644f2f388361e',
      app_id: 'ORKMXLHYI4',
      index: 'staging_bbm_vets',
    },
  },
  flags: {
    allowViewingPolicyLimits: true,
  },
  rules: {
    paymentMethods: {
      monthly: ['STRIPE_BILLING'],
    },
    cancellation: [
      {
        id: 'too-expensive',
        label: 'Too expensive',
        rules: {
          INSIDE_COOLING_OFF: ['term-start'],
          default: [['term-start', 'tomorrow'], ['end-of-billing']],
        },
      },
      {
        id: 'pet-missing',
        label: 'Pet missing',
        rules: {
          INSIDE_COOLING_OFF: ['term-start'],
          default: ['term-start', 'tomorrow'],
        },
      },
      {
        id: 'pet-rip',
        label: 'Pet RIP',
        rules: {
          INSIDE_COOLING_OFF: ['term-start'],
          default: ['term-start', 'tomorrow'],
        },
      },
      {
        id: 'pet-stolen',
        label: 'Pet stolen',
        rules: {
          INSIDE_COOLING_OFF: ['term-start'],
          default: ['term-start', 'tomorrow'],
        },
      },
      {
        id: 'transferred-owner',
        label: 'Transferred owner',
        rules: {
          INSIDE_COOLING_OFF: ['term-start'],
          default: ['today', 'end-of-billing'],
        },
      },
      {
        id: 'service-unacceptable',
        label: 'Service unacceptable',
        rules: {
          INSIDE_COOLING_OFF: ['term-start'],
          default: [['term-start', 'tomorrow'], ['end-of-billing']],
        },
      },
      {
        id: 'claim-denied-waiting-period',
        label: 'Claim denied - waiting period',
        rules: {
          INSIDE_COOLING_OFF: ['term-start'],
          default: [['term-start', 'tomorrow'], ['end-of-billing']],
        },
      },
      {
        id: 'claim-denied-pre-ex',
        label: 'Claim denied - pre-existing',
        rules: {
          INSIDE_COOLING_OFF: ['term-start'],
          default: ['term-start', 'end-of-billing'],
        },
      },
      {
        id: 'customer-self-funding',
        label: 'Customer self-funding',
        rules: {
          INSIDE_COOLING_OFF: ['term-start'],
          default: [['term-start', 'tomorrow'], ['end-of-billing']],
        },
      },
      {
        id: 'switched-to-competitor',
        label: 'Switched to competitor',
        rules: {
          INSIDE_COOLING_OFF: ['term-start'],
          default: [['term-start', 'tomorrow'], ['end-of-billing']],
        },
      },
      {
        id: 'unable-to-switch',
        label: 'Unable to switch',
        rules: {
          INSIDE_COOLING_OFF: ['term-start'],
          default: ['today', 'end-of-billing'],
        },
      },
      {
        id: 'no-reason-provided',
        label: 'No reason provided',
        rules: {
          INSIDE_COOLING_OFF: ['term-start'],
          default: [['term-start', 'tomorrow'], ['end-of-billing']],
        },
      },
      {
        id: 'compliance-customer-breach',
        label: 'Compliance customer breach',
        rules: {
          INSIDE_COOLING_OFF: ['term-start'],
          default: ['term-start', 'end-of-billing'],
        },
      },
      {
        id: 'risk-measurably-increased',
        label: 'Risk measurably increased',
        rules: {
          INSIDE_COOLING_OFF: ['term-start'],
          default: ['term-start', 'end-of-billing'],
        },
      },
      {
        id: 'violation-of-law',
        label: 'Violation of law',
        rules: {
          INSIDE_COOLING_OFF: ['term-start'],
          default: ['term-start', 'end-of-billing'],
        },
      },
      {
        id: 'customer-misrepresentation',
        label: 'Customer misrepresentation',
        rules: {
          INSIDE_COOLING_OFF: ['term-start'],
          default: ['term-start', 'end-of-billing'],
        },
      },
      {
        id: 'underwriting-decision',
        label: 'Underwriting decision',
        rules: {
          INSIDE_COOLING_OFF: ['term-start'],
          default: ['term-start', 'end-of-billing'],
        },
      },
      {
        id: 'merged-policy',
        label: 'Merged policy',
        rules: {
          INSIDE_COOLING_OFF: ['term-start'],
          default: ['today', 'end-of-billing'],
        },
      },
      {
        id: 'system-limitation',
        label: 'System limitation',
        rules: {
          INSIDE_COOLING_OFF: ['term-start'],
          default: ['today', 'end-of-billing'],
        },
      },
      {
        id: 'move-to-another-product',
        label: 'Moved to another product',
        rules: {
          INSIDE_COOLING_OFF: ['term-start'],
          default: ['today', 'end-of-billing'],
        },
      },
      {
        id: 'non-payment',
        label: 'Non payment',
        unselectable: true,
      },
    ],
  },
}

export default deepFreeze(productLineConfig)
