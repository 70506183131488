import dayjs from '@/lib/dayjs'
import { isJuly2020Model } from '@/lib/utils/policies'

/**
 * Get the first/only insured entity from a quote/policy data object.
 *
 * If the data object has a single entity (either due to using
 * `data.insured_entity` or simply having a single entry in the
 * `data.insured_entities` property), that entity will be returned.
 *
 * If the data object has multiple entities, the first one in the array will be
 * returned.
 *
 * @param {object} data The quote or policy data object.
 * @returns {object} The first/only insured entity in the data object.
 */
export const insuredEntity = (data) =>
  data.insured_entity ?? data.insured_entities[0]

/**
 * Get an array of all insured entities from a quote/policy data object.
 *
 * If the data object only has a single entity (either due to using
 * `data.insured_entity` or simply having a single entry in the
 * `data.insured_entities` property), an array will still be returned.
 *
 * @param {object} data The quote or policy data object.
 * @returns {object[]} An array of insured entities in the data object.
 */
export const insuredEntities = (data) =>
  (data.insured_entity ? [data.insured_entity] : data.insured_entities) || []

/**
 * Get a string naming all insured entities from a quote/policy data object.
 *
 * @param {object} data The quote or policy data object.
 * @returns {string} The names of the insured entities, in human-readable list format.
 */
export function insuredEntityNames(data) {
  return insuredEntities(data)
    .map((entity) => entity.pet_name)
    .join(', ')
}

/**
 * Return the total price of all the coverage options in a given bundle.
 *
 * @param {object} bundle The bundle to calculate the price for.
 * @param {object[]} bundle.compulsory The list of compulsory coverages for the bundle.
 * @param {object[]} bundle.optional The list of optional coverages for the bundle.
 * @param {string} bundle.optional.name The name of the coverage.
 * @param {string[]} selectedCoverageOptions List of the optional coverage options to include in the price calculations.
 * @returns {number} The total cost of all selected coverages for the bundle.
 */
export function getBundlePrice(bundle, selectedCoverageOptions) {
  const options = getBundleCoverageOptions(bundle, selectedCoverageOptions)
  return options.reduce((total, option) => total + option.monthlyCost, 0)
}

/**
 * Return all the compulsory coverage options and all the selected optional coverages for a given bundle.
 *
 * @param {object} bundle The bundle to calculate the price for.
 * @param {object[]} bundle.compulsory The list of compulsory coverages for the bundle.
 * @param {object[]} bundle.optional The list of optional coverages for the bundle.
 * @param {string} bundle.optional.name The name of the coverage.
 * @param {string[]} selectedCoverageOptions List of the optional coverage options to include in the price calculations.
 * @returns {object[]} List of all compulsory coverage options and selected optional coverages.
 */
export function getBundleCoverageOptions(bundle, selectedCoverageOptions) {
  const selectedOptionals = bundle.optional.filter((optional) =>
    selectedCoverageOptions.includes(optional.name)
  )
  return [...bundle.compulsory, ...selectedOptionals]
}

/**
 * @param {string} coverage The key of the coverage.
 * @param {string} variable The key of the coverage variable.
 * @param {string} value The value of the coverage variable.
 * @returns {string} A combined string key of the given params.
 */
export function genVariableId(coverage, variable, value) {
  return `${coverage}:${variable}:${value}`
}

/**
 * Returns the Policy's annual price.
 *
 * @param {object} policy Policy object
 * @returns {number} The annual price of the policy.
 */
export function getPolicyAnnualPrice(policy) {
  return isJuly2020Model(policy)
    ? Object.values(policy.coverages).reduce(
        (total, coverage) => total + (coverage.price?.annual?.amount ?? 0),
        0
      )
    : (policy.products[0]?.price.annual.amount ?? null)
}

/**
 * Returns the Policy's original start date.
 *
 * @param {object} policy Policy object
 * @returns {string} The policy original start date or latest inception date.
 */
export function getPolicyOriginalStartDate(policy) {
  // TODO: when "policy_original_start_date" is missing, we have a hacky fix
  // that calculates the original start date based on the "renewal_count" and
  // "inception_date".
  // We should remove this hacky fix when BE fixes this on their end and
  // "policy_original_start_date" is guaranteed to be there for all policies.

  const originalStartDate = policy?.metadata?.policy_original_start_date
  const renewalCount = policy?.renewal_count || 0
  const inceptionDate =
    policy?.data?.policy?.inception_date ?? policy?.inception_date

  if (originalStartDate) {
    return originalStartDate
  } else if (inceptionDate) {
    return dayjs
      .utc(inceptionDate)
      .subtract(renewalCount, 'year')
      .format('YYYY-MM-DDTHH:mm:ss[Z]')
  } else {
    throw new Error('Cannot determine the policy original start date')
  }
}

/**
 * Determine whether a given product line should use quick quotes.
 *
 * @param {string} agency The agency the product line belongs to.
 * @param {string} productLine The key of the product line to check.
 * @returns {boolean} Whether the given product line should use quick quotes.
 */
export function isQuickQuoteProductLine(agency, productLine) {
  // Product lines that don't support subscriptions and should follow the legacy flows.
  // Hardcoded here to prevent extra requests anywhere we need to make this decision.
  const legacyProductLines = {
    BBM: ['cat-dog', 'medical-travel', 'pet', 'exotic-pet', 'craig-fruit-2'],
    'BBM-SE': ['katt-hund'],
  }

  // Assume that if it's not listed here, then it's should use quick quotes.
  return !legacyProductLines[agency]?.includes(productLine)
}

/**
 * Determine whether a given product line can be used with subscriptions.
 *
 * @param {string} agency The agency the product line belongs to.
 * @param {string} productLine The key of the product line to check.
 * @returns {boolean} Whether the given product line supports subscription model.
 */
export function isSubscriptionProductLine(agency, productLine) {
  // Product lines that don't support subscriptions and should follow the legacy flows.
  // Hardcoded here to prevent extra requests anywhere we need to make this decision.
  const legacyProductLines = {
    BBM: ['cat-dog', 'medical-travel', 'pet', 'exotic-pet', 'craig-fruit-2'],
    'BBM-SE': ['katt-hund'],
  }

  // Assume that if it's not listed here, then it's using subscriptions.
  return !legacyProductLines[agency]?.includes(productLine)
}
