import { defineStore } from 'pinia'
import { computed, ComputedRef, Ref, ref } from 'vue'

import { Order, OrderAction } from '@/domain/Order'
import {
  ItemType,
  ItemTypeParser,
} from '@/services/parsers/Shop/ItemTypeParser'
import ShopService from '@/services/Shop/ShopService'

interface UseOrderOperationsStoreResult {
  order: Ref<Nullable<Order>>

  reset: () => void
  cancelProduct: (cancellationProps: CancellationProps) => Promise<Maybe<Order>>
  adjustProduct: (adjustProps: AdjustProps) => Promise<Maybe<Order>>
  updateOrderEffectiveDate: (params: {
    effectiveDate: string
    basketVersion: 1 | 2
  }) => Promise<Maybe<Order>>
  createChangePaymentDateOrder: (params: {
    customerId: string
    effectiveDate: string
    paymentDay: number
    basketVersion: 1 | 2
  }) => Promise<Maybe<Order>>
  selectProducts: (selectItemProps: SelectItemProps) => Promise<Maybe<Order>>
  orderAlreadyHasCancellation: ComputedRef<boolean>
}

export interface CancellationitemInfo {
  petId: string
  type: ItemType
  reason: string
}

export interface AdjustItemInfo {
  petId: string
  type: ItemType
}

export interface SelectItemProductProps {
  sku: string
  options: string[]
}
export interface SelectItemProps {
  petId: string
  products: SelectItemProductProps[]
}

export interface CancellationProps {
  customerId: string
  effectiveDate: string
  items: CancellationitemInfo[]
}

export interface AdjustProps {
  customerId: string
  effectiveDate: string
  items: AdjustItemInfo[]
}

type OperationRules = Record<OrderAction, OrderAction[]>

const operationRules: OperationRules = {
  remove: ['remove'],
  renewal: ['renewal'],
  adjust: ['adjust', 'create'],
  create: ['adjust', 'create'],
}

export const useOrderOperationsStore = defineStore(
  'orderOperations',
  (): UseOrderOperationsStoreResult => {
    const order = ref<Nullable<Order>>(null)

    const reset = (): void => {
      order.value = null
    }

    const orderActions = computed(
      () =>
        order.value?.pets
          .flatMap((pet) => pet.items)
          .map((item) => item.action) ?? []
    )

    const validateOperation = (operation: OrderAction): boolean => {
      if (!order.value) {
        return true
      }

      if (orderActions.value.length === 0) {
        return true
      }

      if (
        operation === 'remove' &&
        orderActions.value.some((action) => action === 'remove')
      ) {
        return true
      }

      return orderActions.value.every((existingAction) =>
        operationRules[existingAction].includes(operation)
      )
    }

    const updateOrderEffectiveDate = async ({
      effectiveDate,
      basketVersion,
    }: {
      effectiveDate: string
      basketVersion: 1 | 2
    }): Promise<Maybe<Order>> => {
      if (!order.value?.id) {
        return
      }
      const newOrder = await ShopService.updateEffectiveDate({
        effectiveDate,
        orderId: order.value?.id,
        basketVersion,
      })

      order.value = newOrder

      return order.value
    }

    const createChangePaymentDateOrder = async ({
      customerId,
      effectiveDate,
      paymentDay,
      basketVersion,
    }: {
      customerId: string
      effectiveDate: string
      paymentDay: number
      basketVersion: 1 | 2
    }): Promise<Maybe<Order>> => {
      const newOrder = await ShopService.changePaymentDateOrder({
        basketVersion,
        customerId,
        paymentDay,
        effectiveDate,
      })

      order.value = newOrder
      return order.value
    }

    const adjustProduct = async (
      adjustProps: AdjustProps
    ): Promise<Maybe<Order>> => {
      if (order.value) {
        throw new Error(
          'Cannot perform adjustment when there is already an order in progress.'
        )
      }

      order.value = await ShopService.createAdjustItemOrder({
        customerId: adjustProps.customerId,
        effectiveDate: adjustProps.effectiveDate,
        items: adjustProps.items.map((item) => ({
          pet: item.petId,
          type: ItemTypeParser.serialise(item.type),
          action: 'adjust',
        })),
      })

      return order.value
    }

    const selectProducts = async (
      selectItemProps: SelectItemProps
    ): Promise<Maybe<Order>> => {
      const { petId, products } = selectItemProps
      if (!order.value) {
        throw new Error('Cannot select products without an order.')
      }

      order.value = await ShopService.selectProductsForPetBasketV2({
        petId,
        orderId: order.value.id,
        products: products,
      })

      return order.value
    }
    const cancelProduct = async (
      cancellationProps: CancellationProps
    ): Promise<Maybe<Order>> => {
      if (!validateOperation('remove')) {
        throw new Error(
          'The current basket does not support adding a cancel operation'
        )
      }

      if (order.value && cancellationProps.items.length > 1) {
        throw new Error(
          'Cannot perform bulk cancellation when there is already an order in progress.'
        )
      }

      if (order.value) {
        const cancelledItem = cancellationProps.items[0]

        order.value = await ShopService.updateCancellationOrder({
          basketVersion: 2, // cancellations always use basket v2
          orderId: order.value.id,
          pet: cancelledItem.petId,
          reason: cancelledItem.reason,
          type: ItemTypeParser.serialise(cancelledItem.type),
          action: 'remove',
        })
      } else {
        order.value = await ShopService.createCancellationOrder({
          customerId: cancellationProps.customerId,
          effectiveDate: cancellationProps.effectiveDate,
          items: cancellationProps.items.map((item) => ({
            pet: item.petId,
            type: ItemTypeParser.serialise(item.type),
            reason: item.reason,
            action: 'remove',
          })),
        })
      }

      return order.value
    }

    const orderAlreadyHasCancellation = computed(() => {
      return (
        order.value?.pets.some((pet) =>
          pet.items.some((item) => item.action === 'remove')
        ) ?? false
      )
    })

    return {
      order,
      orderAlreadyHasCancellation,

      reset,
      updateOrderEffectiveDate,
      createChangePaymentDateOrder,
      cancelProduct,
      adjustProduct,
      selectProducts,
    }
  }
)
