import {
  AccountSummary,
  AccountSummaryDocuments,
} from '@/domain/Account/AccountSummary'
import { FinancialOverview } from '@/domain/Account/FinancialOverview'
import ErrorResponseFactory from '@/domain/Error/ErrorResponseFactory'
import AccountParser from '@/services/parsers/Account/AccountParser'
import FinancialOverviewParser from '@/services/parsers/Account/FinancialOverviewParser'

import { AccountApiService } from '../ApiService'

export default class AccountService {
  public static async getAccountSummary(
    customerId: string,
    on_date?: string
  ): Promise<AccountSummary> {
    try {
      const searchParams: any = {
        include_items: 'all',
        include_cerf: true,
      }

      if (on_date) {
        searchParams['on_date'] = on_date
      }

      const result = await AccountApiService.get({
        path: `/v2/${customerId}`,
        params: {
          searchParams,
        },
        parse: AccountParser.parse,
      })

      if (on_date) {
        result.loadedOnDate = on_date
      }

      return result
    } catch (error) {
      const errorClass = await ErrorResponseFactory.instantiateErrorClass({
        title: `Unable to get account summary for customer: ${customerId}`,
        originalError: error,
        tags: ['Shop'],
      })

      throw errorClass
    }
  }

  public static async editPaymentDetails(customerId: string): Promise<string> {
    try {
      const result = AccountApiService.post({
        path: `/v2/${customerId}/begin-payment-method-capture`,
        parse: (data: any) => data.payment_flow_id,
      })

      return result
    } catch (error) {
      const errorClass = await ErrorResponseFactory.instantiateErrorClass({
        title: `Unable to edit payment details for customer: ${customerId}`,
        originalError: error,
        tags: ['Shop'],
      })

      throw errorClass
    }
  }

  public static async updatePaymentDetails(params: {
    customerId: string
    paymentMethodId: string
  }): Promise<void> {
    const { customerId, paymentMethodId } = params

    try {
      await AccountApiService.post({
        path: `/v2/${customerId}/update-payment-method`,
        params: {
          json: {
            payment_method_id: paymentMethodId,
          },
        },
      })
    } catch (error) {
      const errorClass = await ErrorResponseFactory.instantiateErrorClass({
        title: `Unable to get update payment details for customer: ${customerId}`,
        originalError: error,
        tags: ['Shop'],
      })

      throw errorClass
    }
  }

  public static async getCustomerDocuments(params: {
    customerId: string
  }): Promise<AccountSummaryDocuments> {
    const { customerId } = params

    try {
      const result = await AccountApiService.get({
        path: `/v2/${customerId}/documents`,
        parse: AccountParser.parseDocuments,
      })

      return result
    } catch (error) {
      const errorClass = await ErrorResponseFactory.instantiateErrorClass({
        title: `Unable to get customer documents for customer: ${customerId}`,
        originalError: error,
        tags: ['Shop'],
      })

      throw errorClass
    }
  }

  public static async getFinancialOverview({
    customerId,
  }: {
    customerId: string
  }): Promise<FinancialOverview> {
    try {
      return await AccountApiService.get({
        path: `/v2/experimental/financial-overview/${customerId}`,
        parse: FinancialOverviewParser.parseFinancialOverview,
      })
    } catch (error) {
      const errorClass = await ErrorResponseFactory.instantiateErrorClass({
        title: `Unable to get account financial overview for customer: ${customerId}`,
        originalError: error,
        tags: ['Shop'],
      })

      throw errorClass
    }
  }
}
