<template>
  <span
    class="copyable-text-container"
    @click="!iconClickOnly && copyToClipboard($event)"
  >
    <span ref="contentRef" class="copyable-text-content">
      <slot />
    </span>
    <span
      class="copy-to-clipboard-icon"
      @click="iconClickOnly && copyToClipboard($event)"
    >
      <ElIcon><CopyIcon title="Copy to clipboard" /></ElIcon>
    </span>
  </span>
</template>

<script lang="ts" setup>
import { DocumentCopy as CopyIcon } from '@element-plus/icons-vue'
import { useClipboard } from '@vueuse/core'
import { ElMessage } from 'element-plus'
import { shallowRef } from 'vue'

interface Props {
  text?: string | number
  successMessage?: string
  iconClickOnly?: boolean
}

const {
  text,
  successMessage = '',
  iconClickOnly = false,
} = defineProps<Props>()

const contentRef = shallowRef<Nullable<HTMLElement>>(null)

const { copy } = useClipboard()

async function copyToClipboard(e: Event) {
  e.stopPropagation()
  try {
    const copyText = String(text ?? contentRef.value?.textContent ?? '')
    await copy(copyText)
    ElMessage.success({
      message: successMessage || `"${copyText}" copied!`,
      duration: 800,
    })
  } catch {
    ElMessage.error('Unable to copy to clipboard')
  }
}
</script>

<style lang="scss" scoped>
.copyable-text-container {
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  .copy-to-clipboard-icon {
    opacity: 0;
    padding-left: 0.25em;
    transition: opacity 0.3s ease;
  }

  &:hover {
    .copy-to-clipboard-icon {
      opacity: 0.5;
    }
  }
}
</style>
