/* eslint-disable @typescript-eslint/explicit-function-return-type */

import type { RouteLocationNormalized } from 'vue-router'

export default [
  {
    name: 'customer-list',
    path: '/customers',
    component: () => import('@/views/customers/CustomerList/CustomerList.vue'),
    props: (to: RouteLocationNormalized) => ({
      page: to.query.page,
      search: to.query.search,
      productline: to.query.productline,
    }),
    meta: {
      title: 'Customers',
      legacy: false,
    },
  },
  /*
   * @todo This is a temporary redirect added in when turning off the old customer view pages,
   * so that if there are any straggling links to the old customer views, they will redirect to the new.
   * This redirect should be removed after the new customer dashboard has bedded in.
   */
  {
    name: 'customer-view',
    path: '/customers/:uuid/:section?',
    redirect: (to: RouteLocationNormalized) => ({
      name: 'customer-dashboard',
      params: { ...to.params, section: 'pets' },
    }),
  },

  {
    path: '/customer/:uuid/:basketId(bkt_[^/]+|ord_[^/]+)?',
    component: () => import('@/views/customers/CustomerDashboardWrapper'),
    meta: {
      title: 'Customer dashboard',
    },
    props: (to: RouteLocationNormalized) => ({
      customerId: to.params.uuid,
      basketId: to.params.basketId,
    }),
    children: [
      {
        name: 'renewals',
        path: 'renewals/:date?/:petId?',
        meta: {
          title: 'Renewals | Customer dashboard',
        },
        component: () =>
          import(
            '@/views/customers/CustomerRenewalDetails/CustomerRenewal.vue'
          ),
        props: (to: RouteLocationNormalized) => ({
          customerId: to.params.uuid,
        }),
      },
      {
        name: 'basket-snapshot',
        path: 'basket-snapshot',
        meta: {
          title: 'Basket snapshot | Customer dashboard',
        },
        component: () => import('@/views/customers/CustomerBasketDetails'),
      },
      {
        name: 'policy-snapshot',
        path: 'policy/:policyId/:date?',
        meta: {
          title: 'Policy Snapshot | Customer dashboard',
        },
        component: () => import('@/views/customers/CustomerPolicySnapshot'),
        props: (to: RouteLocationNormalized) => ({
          customerId: to.params.uuid,
          policyId: to.params.policyId,
          date: to.params.date,
        }),
      },
      {
        name: 'customer-dashboard',
        path: ':section?',
        meta: {
          title: 'Customer',
        },
        component: () => import('@/views/customers/CustomerDashboard'),
        props: (to: RouteLocationNormalized) => ({
          customerId: to.params.uuid,
          section: to.params.section,
        }),

        children: [
          {
            name: 'edit-customer',
            path: 'edit',
            component: {},
            meta: {
              title: 'Edit customer',
            },
          },
          {
            name: 'edit-product',
            path: ':petId/:productType/edit',
            components: {},
            meta: {
              title: 'Edit product',
            },
          },
          {
            name: 'edit-customer-pet',
            path: ':petId/edit',
            component: {},
            meta: {
              title: 'Edit pet',
            },
          },
          {
            name: 'edit-effective-date',
            path: 'edit-effective-date',
            component: {},
            meta: {
              title: 'Edit Effective Date',
            },
          },
          {
            name: 'reinstate-policies',
            path: 'reinstate-policies',
            component: {},
            meta: {
              title: 'Reinstatement',
            },
          },
          {
            name: 'add-customer-pet',
            path: 'new',
            component: {},
            meta: {
              title: 'Add new pet',
            },
          },
          {
            name: 'cancel-policy',
            path: ':policyId/cancel',
            component: {},
            meta: {
              title: 'Cancel Policy',
            },
          },
          {
            name: 'cancel-policy-undo',
            path: ':policyId/cancel-undo',
            component: {},
            meta: {
              title: 'Undo Cancel Policy',
            },
          },
          {
            name: 'policy-limits',
            path: ':policyId/view-limits',
            component: {},
            meta: {
              title: 'Policy Limits',
            },
          },
        ],
      },
    ],
  },
]
