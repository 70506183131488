import merge from 'deepmerge'
import { last } from 'lodash-es'
import short from 'short-uuid'

import dayjs, { Dayjs, DayJsParsable } from '@/lib/dayjs'

// This is the same alphabet as the Python `short-uuid` package uses by default.
export const shortUuid = short(
  '23456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz'
)

export const toShortUuid = (
  uuid: string | short.UUID,
  prefix: string
): string => `${prefix}_${shortUuid.fromUUID(uuid)}`

export const toLongUuid = (id: string): short.UUID => {
  const shortUUID = last(id.split('_')) ?? ''

  return shortUuid.toUUID(shortUUID)
}

/**
 * Capitalise a string.
 *
 * Note: this function capitalises only the first letter of the string and
 * leaves the rest unchanged.
 *
 * @param {string} str The string to capitalise.
 * @returns {string} The capitalised string.
 */
export function capitalize(str?: string): string {
  if (str) {
    return `${str.charAt(0).toUpperCase()}${str.slice(1)}`
  }
  return ''
}

/**
 * Get a summation (total) of (a given property of) all items in an array, optionally
 * filtered.
 *
 * @param {object[]} array The array to iterate.
 * @param {string} [property] The property to pluck from each item to use for summation.
 * If not given, the item itself is used for summation.
 * @param {Function} [predicate] An optional function used to filter items.
 * @returns {number} The sum of al items' `property` properties, only including those
 * that pass the predicate test (if it is present).
 */
export function sum(
  array: Array<number | Record<string, any>>,
  property?: string,
  predicate?: (item: any) => boolean
): number {
  return array.reduce<number>(
    (total, item) =>
      !predicate || predicate(item)
        ? total +
          ((property ? (<Record<string, any>>item)[property] : <number>item) ||
            0)
        : total,
    0
  )
}

const emptyTarget = (value: Array<any> | Object): Array<any> | Object =>
  Array.isArray(value) ? [] : {}

const clone = (
  value: Array<any> | Object,
  options?: merge.Options
): Array<any> | Object => merge(emptyTarget(value), value, options)

/**
 * Merge two arrays???
 *
 * @param {Array} target The target array.
 * @param {Array}  source The source array.
 * @param {object} options The options for the merge.
 * @param {boolean} options.clone Whether or not to clone the array elements when merging.
 * @param {function(Array): boolean} options.isMergeableObject A function that returns a boolean as to whether a given array is mergeable.
 * @returns {Array} The final array.
 */
export function combineMerge(
  target: Array<any>,
  source: Array<any>,
  options: merge.Options
): Array<any> {
  const destination = target.slice()

  source.forEach(function (e, i) {
    if (typeof destination[i] === 'undefined') {
      const cloneRequested = options.clone !== false
      const shouldClone =
        cloneRequested &&
        options.isMergeableObject &&
        options.isMergeableObject(e)
      destination[i] = shouldClone ? clone(e, options) : e
    } else if (options.isMergeableObject && options.isMergeableObject(e)) {
      destination[i] = merge(target[i], e, options)
    } else if (target.indexOf(e) === -1) {
      destination.push(e)
    }
  })
  return destination
}

/**
 * Sorts objects in an array by date.
 *
 * This will not mutate the original array.
 *
 * @param {object[]} objects The array of object to sort.
 * @param {string} [order='DESC'] The order to sort by.
 * @param {string} [propertyToSortBy='created_at'] The property to use to sort by.
 * @returns {object[]} A sorted copy of the array
 */
export function sortObjectsByDate(
  objects: Array<Record<string, any>>,
  order: 'ASC' | 'DESC' = 'DESC',
  propertyToSortBy: string = 'created_at'
): Array<Record<string, any>> {
  return [...objects].sort((a, b) => {
    if (
      typeof a[propertyToSortBy] === 'undefined' ||
      typeof b[propertyToSortBy] === 'undefined'
    ) {
      // can't compare so leave in same position in array
      return 0
    }

    const d1 = new Date(a[propertyToSortBy])
    const d2 = new Date(b[propertyToSortBy])

    if (d1 > d2) {
      return order === 'DESC' ? -1 : 1
    }

    if (d1 < d2) {
      return order === 'DESC' ? 1 : -1
    }

    // Date times are the same
    return 0
  })
}

/**
 * Returns a default effective date for a policy amendment based on the policy
 * inception date.
 *
 * If the inception is in the future, use that date as the default effective
 * date, else use today.
 *
 * @param {string} policyInceptionDateStr the policy inception date
 * @returns {dayjs} the effective cancellation date
 */
export function calculateDefaultEffectiveDate(
  policyInceptionDateStr: DayJsParsable
): Dayjs {
  const policyInceptionDate = dayjs.utc(policyInceptionDateStr)
  const now = dayjs.utc()

  return now.isSameOrBefore(policyInceptionDate, 'day')
    ? policyInceptionDate
    : now.startOf('day')
}

/**
 * Scrolls the page to the element identified by the passed in locator
 *
 * @param {string} id Identifier of an element on the page to be scrolled
 */
export function scrollToId(id: string): void {
  const element = document.querySelector(id)

  if (element) {
    window.scrollTo({
      top: element.getBoundingClientRect().top + window.pageYOffset - 75,
      behavior: 'smooth',
    })
  }
}

type Formatter = (item: string) => string

/**
 * Formats a value based on the specified formatters
 *
 * @param {*} value the value to format
 * @param {Function|Function[]} formatters the formatters to apply
 * @returns {*} the formatted value
 */
export function formatValue(
  value: string,
  formatters?: Formatter | Formatter[]
): string {
  if (Array.isArray(formatters)) {
    return formatters.reduce(
      (str: string, formatter: Formatter) => formatter(str),
      value
    )
  } else {
    return (formatters as Formatter)?.(value) ?? value
  }
}

/**
 * Builds a class list
 *
 * @param {string[]} [classes=[]] classes the columns to get values for
 * @param {string} classModifier a modifier to add to the class
 * @returns {string[]|null} the class list or null if no classes
 */
export function buildClassList(
  classes: string[] = [],
  classModifier: string
): Nullable<string[]> {
  if (classes.length === 0) {
    return null
  }

  return classes.reduce(
    (classList: string[], className: string) =>
      classList.concat([
        className,
        `${className}--${classModifier
          .toLowerCase()
          .replace(/[^\w\d-]|_/g, '-')}`,
      ]),
    []
  )
}

/**
 * Returns human readable agency name 'BBM-SE' => 'ManyPets SE'
 *
 * @param {string} agency the abbreviation of the agency: 'BBM'
 * @returns {string} the formatted agency name
 */
export function generateAgencyName(agency: string): string {
  switch (agency) {
    case 'BBM':
      return 'ManyPets UK'
    case 'BBM-SE':
      return 'ManyPets SE'
    case 'ED':
      return 'Exotic Direct'
    case 'PPS':
      return 'Petplan Sanctuary'
    case 'MP-US':
      return 'ManyPets US'
    default:
      return agency
  }
}

/**
 * Get the ordinal value for a given number
 *
 * @param {number} cardinalValue The numeric value to get the ordinal for
 * @returns string
 */
export function getOrdinal(cardinalValue: number): string {
  const suffixes = new Map([
    ['one', 'st'],
    ['two', 'nd'],
    ['few', 'rd'],
    ['other', 'th'],
  ])

  const pr = new Intl.PluralRules('en-GB', { type: 'ordinal' })
  const rule = pr.select(cardinalValue)
  const suffix = suffixes.get(rule)

  return `${cardinalValue}${suffix}`
}

export function extractCoPayment(coverage: any): Maybe<number> {
  return coverage?.co_payment ?? coverage?.['co-payment']
}
