import { FlagOptions } from '@/domain/Flag'
import type { DocType, DocProvidedBy } from '@/novelClaims/domain/Doc'
import type { Relationship } from '@/novelClaims/domain/Payee'

export interface GenericLoss {
  agency: string
  amount: number
  subcoverages: LossSubcoverages
  carrier: string
  case_id: string
  condition?: LossCondition
  coverage: string
  created_at: string
  excess_adjustment?: LossExcessAdjustment
  date_of_loss: string
  documents?: LossDocument[]
  flags: LossFlag[]
  id: string
  metadata: any
  owner: string
  payee?: LossPayee
  product_line_key: string
  recovered_amount?: number
  ref: string
  status: LossStatus
  treatment_end_date?: string
  updated_at: string
  vets: LossVet[]
}

export type LossStatus =
  | 'OPEN'
  | 'IN_PROGRESS'
  | 'APPROVED'
  | 'DECLINED'
  | 'CLOSED'

export type LossFlag =
  | 'ALTERNATE_PAYEE'
  | 'APPROVED_UNDER_EXCESS'
  | 'POSTCODE_DISCREPANCY'
  | 'PRESCRIPTION'
  | 'REFUND_OF_MILLIE_DEDUCTION'

export interface LossCondition {
  algoliaID: string
  group: string
  id: string
  section: string
  subgroup: string
}

export interface LossSubcoverageItem {
  amount: number
  name: string
}

export type LossSubcoverages = LossSubcoverageItem[]

// NOTE: we currently(Mar 2023) use the documents coming from the /documents endpoint directly
// but if we ever start using the claim.losses[x].documents, then we could reuse some of the
// existing types in the "Doc" domain
export interface LossDocument {
  file_name: string
  mime: string
  url: string
  s3_uri: string
  id: string
  provided_by?: DocProvidedBy
  type?: DocType
}

export interface LossVetBase {
  address_1: string
  id?: number
  practice_name: string
  sid?: string
  vet_practice_id?: string
}
export interface LossVetAlgolia extends LossVetBase {
  id: number
}
export interface LossVetPractice extends LossVetBase {
  vet_practice_id: string
}
export type LossVet = LossVetAlgolia | LossVetPractice

export interface LossPayee {
  payee_id: string
  payee_type: Relationship
  vet_id?: number // Stores the Algolia Vet ID (some old losses may only have vet_id and no vet_practice_id)
  vet_practice_id?: string // Stores the Vet Practice ID

  // "payment_method" is a legacy UK field still used in one place to determine
  // if a legacy payees is "MANUAL" - meaning without payment details.
  // There are other legacy fields, like the "account" object, that are no longer
  // used in PIO so we don't include them here.
  payment_method?: string
}

export interface LossExcessAdjustment {
  amount: number
  description: string
}

export interface ThirdPartyContact {
  address_1?: string
  email?: string
  name?: string
  other_details?: string
  telephone?: string
}

// VetFees have either "vet-fees"(cat-dog) or "vet_fees"(cat-dog-pio) coverage
export type VetFeesLossDataCauseOfLoss = 'ACCIDENT' | 'ILLNESS' | 'NOT_PROVIDED'
export interface VetFeesLossData {
  accident: {
    description: string
    ailment?: string
    ailment_friendly?: string
    body_part?: string
    cause_of_loss?: VetFeesLossDataCauseOfLoss
    deceased?: boolean
    description_of_illness_or_injury?: string
  }
  additional_info?: string
}
export interface VetFeesLoss extends GenericLoss {
  coverage: 'vet_fees' | 'vet-fees'
  data: VetFeesLossData
}

// Death
export type DeathLossDataCauseOfDeath = 'ACCIDENT' | 'ILLNESS' | 'UNKNOWN'
export type DeathLossDataFuneralType = 'BURIAL' | 'CREMATION' | null
export interface DeathLossData {
  additional_info?: string
  breeder?: string
  cause_of_death?: DeathLossDataCauseOfDeath
  euthanised?: boolean
  funeral_type?: DeathLossDataFuneralType
}
export interface DeathLoss extends GenericLoss {
  coverage: 'death'
  data: DeathLossData
}

// Farewell
export type FarewellLossDataFuneralType = 'BURIAL' | 'CREMATION' | null
export interface FarewellLossData {
  additional_info?: string
  funeral_type?: FarewellLossDataFuneralType
  euthanised?: boolean
}
export interface FarewellLoss extends GenericLoss {
  coverage: 'farewell'
  data: FarewellLossData
}

// Liability
export type LiabilityLossDataAccidentType =
  | 'INJURY_TO_THIRD_PARTY'
  | 'INJURY_TO_THIRD_PARTY_ANIMAL'
  | 'ROAD_TRAFFIC_ACCIDENT'
  | 'THIRD_PARTY_PROPERTY_DAMAGE'
export type LiabilityLossDataResponsiblePartyType =
  | 'POLICYHOLDER'
  | 'THIRD_PARTY'
export interface LiabilityPoliceForceInvolvedType {
  address_1?: string
  contact?: string
  name?: string
  telephone?: string
}
export interface LiabilityResponsibleParty {
  address_1?: string
  email?: string
  name?: string
  other_details?: string
  telephone?: string
  type?: LiabilityLossDataResponsiblePartyType
  // TODO: try to make this not optional as it should be
  // "type" is actually a required field but we make it optional so we can set
  // form default of undefined. We do make the field required using the form validation.
}
export interface LiabilityLossData {
  accident?: {
    description: string
    type?: LiabilityLossDataAccidentType
  }
  additional_info?: string
  claim_against_policy_holder: boolean
  history_of_aggression?: boolean
  other_covering_policies?: string
  police_force_involved?: LiabilityPoliceForceInvolvedType
  prejudice_payment_details?: string
  previous_accident?: boolean
  responsible_party: LiabilityResponsibleParty
  third_party_contacts: ThirdPartyContact[]
}
export interface LiabilityLoss extends GenericLoss {
  coverage: 'liability'
  data: LiabilityLossData
}

// TheftLoss
export interface TheftLossLossData {
  additional_info?: string
  third_party_contacts?: ThirdPartyContact[]
  pet_retrieved?: boolean
}
export interface TheftLossLoss extends GenericLoss {
  coverage: 'theft-loss' | 'loss_theft'
  data: TheftLossLossData
}

// PetMinding
export interface PetMindingLossData {
  additional_info?: string
}
export interface PetMindingLoss extends GenericLoss {
  coverage: 'pet-minding' | 'emergency_minding'
  data: PetMindingLossData
}

// Travel
export interface TravelLossData {
  additional_info?: string
}
export interface TravelLoss extends GenericLoss {
  coverage: 'travel' | 'european_travel' | 'world_travel'
  data: TravelLossData
}

// Consultation coverage is either "consultation" or "first_vet" depending on
// the PL (cat-dog vs cat-dog-pio)
export interface ConsultationLossData {
  animal_had_these_symptoms: string
  description: string
  previously_had_similar_problems: boolean
  provider: string
  veterinarian: string
}
export interface ConsultationLoss extends GenericLoss {
  coverage: 'consultation' | 'first_vet'
  data: ConsultationLossData
}

// Wellness losses & data
export type WellnessCoverage =
  | 'dental_cleaning'
  | 'holistic_care'
  | 'parasite_prevention'
  | 'vaccines_and_wellness_exams'
export interface WellnessLossData<T> {
  accident: {
    ailment?: string
    ailment_friendly?: string
    body_part?: string
    deceased?: boolean
    treatment: T[]
  }
  additional_info?: string
}

export type DentalCleaningLossTreatment = 'DENTAL_CLEANING'
export interface DentalCleaningLoss extends GenericLoss {
  coverage: 'dental_cleaning'
  data: WellnessLossData<DentalCleaningLossTreatment>
}

export type HolisticCareLossTreatment =
  | 'SUPPLEMENT_JOINT'
  | 'SUPPLEMENT_ALLERGIES'
  | 'SUPPLEMENT_GASTROENTERIC'
  | 'SUPPLEMENT_MISCELLANEOUS'
  | 'MASSAGE_TREATMENT'
  | 'REIKI_TREATMENT'
  | 'CHIROPRACTIC_TREATMENT'
  | 'ACUPUNCTURE_TREATMENT'
  | 'MISCELANEOUS_TREATMENT_OR_SERVICE'
  | 'THERAPEUTIC_SHAMPOO_OR_SKINCARE_TREATMENTS'
  | 'VITAMINS'
  | 'MISCELANEOUS_PRODUCT'
export interface HolisticCareLoss extends GenericLoss {
  coverage: 'holistic_care'
  data: WellnessLossData<HolisticCareLossTreatment>
}

export type ParasitePreventionLossTreatment =
  | 'FLEA_TICK_PREVENTATIVE'
  | 'HEARTWORM_PREVENTATIVE'
  | 'HEARTWORM_TEST'
  | 'COMBO_TEST'
  | 'FECAL_TEST'
export interface ParasiteRemovalLoss extends GenericLoss {
  coverage: 'parasite_prevention'
  data: WellnessLossData<ParasitePreventionLossTreatment>
}

export type VaccineAndWellnessExamsLossTreatment =
  | 'EXAM'
  | 'VACCINE_DHPP_DHLPP'
  | 'VACCINE_BORDETELLA'
  | 'VACCINE_RABIES'
  | 'VACCINE_FELV'
  | 'VACCINE_FVRCP'
  | 'VACCINE_FIP'
  | 'VACCINE_CANINE_MISC'
  | 'VACCINE_FELINE_MISC'
export interface VaccineAndWellnessExamsLoss extends GenericLoss {
  coverage: 'vaccines_and_wellness_exams'
  data: WellnessLossData<VaccineAndWellnessExamsLossTreatment>
}

export type WellnessLoss =
  | DentalCleaningLoss
  | HolisticCareLoss
  | ParasiteRemovalLoss
  | VaccineAndWellnessExamsLoss

export type Loss =
  | VetFeesLoss
  | DeathLoss
  | FarewellLoss
  | LiabilityLoss
  | PetMindingLoss
  | TheftLossLoss
  | TravelLoss
  | ConsultationLoss
  | WellnessLoss

// OPTIONS
export interface LossStatusOption {
  value: LossStatus
  label: string
  allowedChange: LossStatus[]
}
export const lossStatusOptions: LossStatusOption[] = [
  {
    value: 'OPEN',
    label: 'Open',
    allowedChange: [],
  },
  {
    value: 'IN_PROGRESS',
    label: 'In progress',
    allowedChange: ['DECLINED', 'CLOSED'],
  },
  {
    value: 'APPROVED',
    label: 'Approved',
    allowedChange: ['IN_PROGRESS'],
  },
  {
    value: 'DECLINED',
    label: 'Declined',
    allowedChange: ['IN_PROGRESS'],
  },
  {
    value: 'CLOSED',
    label: 'Closed',
    allowedChange: ['IN_PROGRESS'],
  },
]

export interface LossFlagOption extends FlagOptions {
  value: LossFlag
  label: string
  color: string
}

// We list all the available loss flags here to be used in instanceConfig.
// These should not be imported directly but retrieved from the config instead.
export const AVAILABLE_LOSS_FLAG_OPTIONS: Record<LossFlag, LossFlagOption> = {
  PRESCRIPTION: {
    label: 'Prescription',
    value: 'PRESCRIPTION',
    color: '#90D14F',
  },
  ALTERNATE_PAYEE: {
    label: 'Alternate payee',
    value: 'ALTERNATE_PAYEE',
    color: '#EE3355',
  },
  REFUND_OF_MILLIE_DEDUCTION: {
    label: 'Refund of Millie deduction',
    value: 'REFUND_OF_MILLIE_DEDUCTION',
    color: '#87CEFA',
  },
  APPROVED_UNDER_EXCESS: {
    label: 'Approved under excess',
    value: 'APPROVED_UNDER_EXCESS',
    color: '#005B3E',
  },
  POSTCODE_DISCREPANCY: {
    label: 'Postcode discrepancy',
    value: 'POSTCODE_DISCREPANCY',
    color: '#FFA500',
  },
}

interface LossPayeeTypeOption {
  value: Relationship
  label: string
  shortLabel: string
}
export const lossPayeeTypeOptions: LossPayeeTypeOption[] = [
  {
    value: 'POLICYHOLDER',
    label: 'Policyholder',
    shortLabel: 'CUST',
  },
  {
    value: 'VET',
    label: 'Vet',
    shortLabel: 'VET',
  },
  {
    value: 'OTHER',
    label: 'Other',
    shortLabel: 'OTH',
  },
]

interface LossPaymentMethodOption {
  value: string
  label: string
  shortLabel: string
}

export const lossPaymentMethodOptions: LossPaymentMethodOption[] = [
  {
    value: 'BANK_TRANSFER',
    label: 'Bank transfer',
    shortLabel: 'BT',
  },
  {
    value: 'CHEQUE',
    label: 'Check',
    shortLabel: 'CH',
  },
  {
    value: 'MANUAL',
    label: 'Not provided',
    shortLabel: '-',
  },
]

export const vetFeesLossDataCauseOfLossOptions: [
  VetFeesLossDataCauseOfLoss,
  string,
][] = [
  ['ACCIDENT', 'Accident'],
  ['ILLNESS', 'Illness'],
  ['NOT_PROVIDED', 'Not provided'],
]

export const deathLossDataCauseOfDeathOptions: [
  DeathLossDataCauseOfDeath,
  string,
][] = [
  ['ACCIDENT', 'Accident'],
  ['ILLNESS', 'Illness'],
  ['UNKNOWN', 'Unknown'],
]

export const funeralLossDataTypeOptions: [
  FarewellLossDataFuneralType,
  string,
][] = [
  ['BURIAL', 'Burial'],
  ['CREMATION', 'Cremation'],
]

export const deathLossDataFuneralTypeOptions: [
  DeathLossDataFuneralType,
  string,
][] = [
  ['BURIAL', 'Burial'],
  ['CREMATION', 'Cremation'],
]

export const liabilityLossDataAccidentTypeOptions: [
  LiabilityLossDataAccidentType,
  string,
][] = [
  ['INJURY_TO_THIRD_PARTY', 'Injury to third-party'],
  ['INJURY_TO_THIRD_PARTY_ANIMAL', 'Injury to third-party (animal)'],
  ['ROAD_TRAFFIC_ACCIDENT', 'Road traffic accident'],
  ['THIRD_PARTY_PROPERTY_DAMAGE', 'Third-party property damage'],
]

export const liabilityLossDataResponsiblePartyTypeOptions: [
  LiabilityLossDataResponsiblePartyType,
  string,
][] = [
  ['POLICYHOLDER', 'Policyholder'],
  ['THIRD_PARTY', 'Third-party'],
]

export const wellnessLossDataAccidentTreatmentOptions: Record<
  WellnessCoverage,
  [string, string][]
> = {
  dental_cleaning: [['DENTAL_CLEANING', 'Dental cleaning']],
  holistic_care: [
    ['SUPPLEMENT_JOINT', 'Supplement - joint'],
    ['SUPPLEMENT_ALLERGIES', 'Supplement - allergies'],
    ['SUPPLEMENT_GASTROENTERIC', 'Supplement - gastroenteric'],
    ['SUPPLEMENT_MISCELLANEOUS', 'Supplement - miscellaneous'],
    ['MASSAGE_TREATMENT', 'Massage treatment'],
    ['REIKI_TREATMENT', 'Reiki treatment'],
    ['CHIROPRACTIC_TREATMENT', 'Chiropractic treatment'],
    ['ACUPUNCTURE_TREATMENT', 'Acupuncture treatment'],
    ['MISCELANEOUS_TREATMENT_OR_SERVICE', 'Miscellaneous treatment or service'],
    [
      'THERAPEUTIC_SHAMPOO_OR_SKINCARE_TREATMENTS',
      'Therapeutic shampoo or skincare treatments',
    ],
    ['VITAMINS', 'Vitamins'],
    ['MISCELANEOUS_PRODUCT', 'Miscellaneous products'],
  ],
  parasite_prevention: [
    ['FLEA_TICK_PREVENTATIVE', 'Flea and tick preventative'],
    ['HEARTWORM_PREVENTATIVE', 'Heartworm preventative'],
    ['HEARTWORM_TEST', 'Heartworm test'],
    ['COMBO_TEST', 'Combo test'],
    ['FECAL_TEST', 'Fecal test'],
  ],
  vaccines_and_wellness_exams: [
    ['EXAM', 'Exam'],
    ['VACCINE_DHPP_DHLPP', 'Vaccine - DHPP/DHLPP'],
    ['VACCINE_BORDETELLA', 'Vaccine - Bordetella'],
    ['VACCINE_RABIES', 'Vaccine - Rabies'],
    ['VACCINE_FELV', 'Vaccine - Felv'],
    ['VACCINE_FVRCP', 'Vaccine - FVRCP'],
    ['VACCINE_FIP', 'Vaccine - FIP'],
    ['VACCINE_CANINE_MISC', 'Vaccine - Canine misc'],
    ['VACCINE_FELINE_MISC', 'Vaccine - Feline misc'],
  ],
}

export function isVetFeesCoverage(coverage: string): boolean {
  return ['vet_fees', 'vet-fees'].includes(coverage)
}
export function isWellnessCoverage(coverage: string): boolean {
  return [
    'dental_cleaning',
    'holistic_care',
    'parasite_prevention',
    'vaccines_and_wellness_exams',
  ].includes(coverage)
}

export function isVetFeesLoss(loss: Loss): loss is VetFeesLoss {
  return isVetFeesCoverage(loss.coverage)
}
export function isWellnessLoss(loss: Loss): loss is WellnessLoss {
  return isWellnessCoverage(loss.coverage)
}

export function shouldLossCoverageHaveCondition(coverage: string): boolean {
  return isVetFeesCoverage(coverage) || ['death', 'farewell'].includes(coverage)
}

export interface LossFormData
  extends Partial<
    Pick<
      Loss,
      | 'case_id'
      | 'coverage'
      | 'amount'
      | 'subcoverages'
      | 'recovered_amount'
      | 'date_of_loss'
      | 'treatment_end_date'
      | 'condition'
      | 'vets'
      | 'documents'
      | 'data'
    >
  > {
  payee: LossPayeeFormData
}

export type LossPayeeFormData = LossPayee | {}
