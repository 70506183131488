/* eslint-disable @typescript-eslint/explicit-function-return-type */

import type { RouteLocationNormalized } from 'vue-router'

export default [
  {
    name: 'order-new',
    path: '/order/new',
    component: () => import('@/views/orders/OrderNew.vue'),
    meta: {
      title: 'New quote',
    },
  },
  {
    name: 'order-edit-products',
    path: '/order/:orderId/:customerId/edit-products/:date?',
    component: () => import('@/views/orders/OrderEditProducts.vue'),
    props: (to: RouteLocationNormalized) => ({
      orderId: to.params.orderId,
      customerId: to.params.customerId,
      date: to.params.date,
    }),
    meta: {
      title: 'Edit Products',
    },
  },
  {
    name: 'order-select-products',
    path: '/order/:basketId/select-products',
    component: () => import('@/views/orders/OrderSelectProducts.vue'),
    props: (to: RouteLocationNormalized) => ({
      basketId: to.params.basketId,
    }),
    meta: {
      title: 'New order',
    },
  },
  {
    name: 'order-status',
    path: '/order/:orderId/:customerUuid?/status',
    component: () => import('@/views/orders/OrderStatus.vue'),
    props: (to: RouteLocationNormalized) => ({
      orderId: to.params.orderId,
      customerUuid: to.params.uuid,
    }),
  },
  {
    name: 'v2-basket-select-products',
    path: '/v2/quotes/:basketId/select-products',
    component: () => import('@/views/v2-baskets/BasketSelectProducts.vue'),
    props: (to: RouteLocationNormalized) => ({
      basketId: to.params.basketId,
    }),
    meta: {
      title: 'New quote',
    },
  },
]
