import { cloneDeep } from 'lodash-es'

import { BasketCustomerChange } from '@/@types/Basket'
import { AgencyName, ProductLineName } from '@/@types/Config'
import { DeepPartial } from '@/@types/general'
import { Lifecycle } from '@/@types/Lifecycle'
import {
  getOptionDetails,
  getProductDetails,
} from '@/application/getProductDetails'
import {
  AccountSummary,
  AccountSummaryAddress,
  AccountSummaryCustomerHydrated,
  AccountSummaryHydrated,
  PaymentSchedule,
  AccountSummaryPet,
  AccountSummaryPetHydrated,
} from '@/domain/Account/AccountSummary'
import {
  AccountSummaryItem,
  AccountSummaryItemProduct,
  AccountSummaryItemOption,
} from '@/domain/Account/AccountSummaryItem'
import {
  AccountSummaryItemHydrated,
  AccountSummaryItemProductHydrated,
  AccountSummaryItemOptionHydrated,
} from '@/domain/Account/AccountSummaryItemHydrated'
import { CustomerAddress, CustomerWithAddress } from '@/domain/Customer'
import { InsuredEntity } from '@/domain/Quote/InsuredEntity'
import { Subscription } from '@/domain/Subscription'
import { Dayjs } from '@/lib/dayjs'
import { getOrdinal, toLongUuid, toShortUuid } from '@/lib/utils'
import { getPolicyTerminationInfo } from '@/lib/utils/lifecycles'
import { petAgeFromDateOfBirth } from '@/lib/utils/petAge'
import { getCurrentPolicySnapshot } from '@/lib/utils/policySnapshot'
import { getNextSubscriptionBillingDate } from '@/lib/utils/subscriptions'

export default class AccountSummaryHydratedAdapter {
  public subscription: Nullable<Subscription> = null

  constructor(subscription?: Subscription) {
    this.subscription = subscription ?? null
  }

  public async hydrateAccountSummary(params: {
    accountSummary: AccountSummary
    lifecycles: Lifecycle[]
    isSgp: boolean
  }): Promise<AccountSummaryHydrated> {
    const { accountSummary, lifecycles, isSgp } = params

    if (!isSgp) {
      this.updateAccountSummaryForNonSgp({ accountSummary })
    }

    const nextBillingDate = accountSummary.payment?.schedule?.[0]?.due ?? null
    const pets = await this.hydratePets({
      accountSummary,
      lifecycles,
      nextBillingDate,
    })

    return {
      ...accountSummary,
      customer: {
        ...accountSummary.customer,
        uuid: toLongUuid(accountSummary.customer.id),
      },
      hydrated: true,
      pets,
      isSgp,
    }
  }

  public isNonSgpCustomer(): boolean {
    // we only inject subscription when the customer is not sgp
    return this.subscription !== null
  }

  public async hydratePets(params: {
    accountSummary: AccountSummary
    lifecycles: Lifecycle[]
    nextBillingDate: Nullable<string>
  }): Promise<AccountSummaryPetHydrated[]> {
    const { accountSummary, lifecycles, nextBillingDate } = params
    const petsPromises = accountSummary.pets.map((accountSummaryPet) =>
      this.hydrateAccountSummaryPet({
        accountSummaryPet,
        lifecycles,
        ownerId: toLongUuid(accountSummary.customer.id),
        nextBillingDate,
      })
    )

    return await Promise.all(petsPromises)
  }

  private updateAccountSummaryForNonSgp(params: {
    accountSummary: AccountSummary
  }): void {
    const { accountSummary } = params

    const nextBillingDate = getNextSubscriptionBillingDate({
      subscription: this.subscription,
      inceptionDates: accountSummary.pets
        .flatMap((pet) => pet.items)
        .map((item) => item.startDate),
    }) as Dayjs

    const schedule: PaymentSchedule[] = [
      {
        due: nextBillingDate?.format('YYYY-MM-DD') ?? null,
        total: accountSummary.price.monthlyTotal.total,
        type: 'regular',
        coverPeriod: {
          startDate: nextBillingDate?.format('YYYY-MM-DD') ?? 'Unknown',
          endDate:
            nextBillingDate
              ?.add(1, 'month')
              .subtract(1, 'day')
              .format('YYYY-MM-DD') ?? 'Unknown',
        },
      },
    ]

    if (!accountSummary.payment) {
      accountSummary.payment = {
        day: nextBillingDate.day(),
        paymentMethodDetails: null,
        schedule: schedule,
        inArrears: false,
        collectOnPaymentDay: true,
      }
    } else if (!accountSummary.payment.schedule?.length) {
      accountSummary.payment.schedule = schedule
    }
  }

  public async hydrateAccountSummaryPet(params: {
    accountSummaryPet: AccountSummaryPet
    lifecycles: Lifecycle[]
    ownerId: string
    nextBillingDate: Nullable<string>
  }): Promise<AccountSummaryPetHydrated> {
    const { accountSummaryPet, lifecycles, ownerId, nextBillingDate } = params

    const itemsPromises = accountSummaryPet.items.map(
      (accountSummaryItem: AccountSummaryItem) =>
        this.hydrateAccountSummaryItem({
          accountSummaryItem,
          lifecycles,
          ownerId,
          nextBillingDate,
        })
    )

    const items = await Promise.all(itemsPromises)

    return {
      ...accountSummaryPet,
      age: petAgeFromDateOfBirth(accountSummaryPet.dateOfBirth),
      items,
    }
  }

  public async hydrateAccountSummaryItem(params: {
    accountSummaryItem: AccountSummaryItem
    lifecycles: Lifecycle[]
    ownerId: string
    nextBillingDate: Nullable<string>
  }): Promise<AccountSummaryItemHydrated> {
    const { accountSummaryItem, lifecycles, ownerId, nextBillingDate } = params

    const snapshot = getCurrentPolicySnapshot(lifecycles, accountSummaryItem.id)

    const lifecycle = lifecycles.find(
      (lifecycle) => lifecycle.policy === accountSummaryItem.id
    )

    if (!lifecycle) {
      throw new Error(
        `Unable to find lifecycle for policy ${accountSummaryItem.id}`
      )
    }

    if (!snapshot) {
      throw new Error(
        `Could not find policy snapshot for ${accountSummaryItem.id}`
      )
    }

    const product = await this.hydrateAccountSummaryItemProduct(
      accountSummaryItem.product
    )

    const optionsPromises = accountSummaryItem.options.map(
      this.hydrateAccountSummaryItemOptions
    )

    const options = await Promise.all(optionsPromises)

    return {
      id: accountSummaryItem.id,
      uuid: toLongUuid(accountSummaryItem.id),
      ownerId,
      ref: accountSummaryItem.ref,
      type: accountSummaryItem.type,
      productLine: snapshot.productLine as ProductLineName, // legacy lifecycles because UK has cd/cdp we can't use type
      productName: accountSummaryItem.productName,
      petId: accountSummaryItem.petId,
      startDate: accountSummaryItem.startDate,
      endDate: accountSummaryItem.endDate,
      nextBillingDate, // legacy for non-sgp s-subscription
      firstPolicyYearStartDate: snapshot.firstPolicyYearStartDate, // legacy lifecycles
      cancellationDate: snapshot.cancellationDate, // legacy lifecycles
      terminationInfo: getPolicyTerminationInfo({ lifecycle }), // legacy lifecycles
      policy: {
        policyDocumentId: snapshot.policyDocumentId, // legacy lifecycles
        status: accountSummaryItem.policy?.status,
        policyYearOrdinal: accountSummaryItem.policy?.termNumber
          ? getOrdinal(accountSummaryItem.policy?.termNumber ?? 0)
          : undefined,
        coPayment: accountSummaryItem.policy?.coPayment,
        excess: accountSummaryItem.policy?.excess,
        limit: accountSummaryItem.policy?.limit,
        policyUuid: accountSummaryItem.policy?.policyUuid,
        coolingOff: accountSummaryItem.policy?.coolingOff,
        waitingPeriodEndDate: accountSummaryItem.policy?.waitingPeriodEndDate,
      },
      product,
      options,
      price: accountSummaryItem.price,
      carrier: snapshot.carrier, // legacy lifecycles
      affiliateCode: accountSummaryItem.affiliateCode,
    }
  }

  public async hydrateAccountSummaryItemOptions(
    data: AccountSummaryItemOption
  ): Promise<AccountSummaryItemOptionHydrated> {
    const details = await getOptionDetails(data.sku)

    return {
      ...data,
      details,
    }
  }

  public async hydrateAccountSummaryItemProduct(
    data: AccountSummaryItemProduct
  ): Promise<AccountSummaryItemProductHydrated> {
    const details = await getProductDetails(data.sku)

    return {
      ...data,
      details,
    }
  }

  public hydrateAccountSummaryLegacy(params: {
    customer: CustomerWithAddress
  }): AccountSummaryHydrated {
    const { customer } = params

    return {
      customer: this.hydrateCustomerLegacy({
        customer,
      }),
      pets: [],
      price: {
        monthlyTotal: {
          total: 0,
          discount: 0,
          tax: 0,
          subtotal: 0,
          discountPercentage: 0,
          discountType: undefined,
        },
        termTotal: {
          total: 0,
          discount: 0,
          tax: 0,
          subtotal: 0,
          discountPercentage: 0,
          discountType: undefined,
        },
        surcharges: [],
      },
      hydrated: true,
      isSgp: false,
    }
  }

  public hydrateCustomerLegacy(params: {
    customer: CustomerWithAddress
  }): AccountSummaryCustomerHydrated {
    const { customer } = params

    return {
      id: toShortUuid(customer.uuid, 'cus'),
      uuid: customer.uuid,
      email: customer.email ?? '',
      title: customer.title,
      firstName: customer.first_name,
      lastName: customer.last_name,
      phone: customer.telephone,
      dateOfBirth: customer.date_of_birth,
      address: this.hydrateCustomerAddressLegacy({
        address: customer.address,
      }),
      ssn: customer.metadata?.swedish_identity_number,
    }
  }

  public hydrateCustomerAddressLegacy(params: {
    address: CustomerAddress | {}
  }): AccountSummaryAddress {
    const { address } = params

    return {
      line1: 'line1' in address ? address.line1 : '',
      line2: 'line2' in address ? address.line2 : '',
      city: 'city' in address ? address.city : '',
      country: 'country' in address ? address.country : '',
      stateOrCounty: 'county' in address ? address.county : '',
      postcode: 'postcode' in address ? address.postcode : '',
    }
  }

  public static insuredEntityToAccountSummaryPetHydrated(params: {
    agencyName: AgencyName
    insuredEntity: Partial<InsuredEntity>
  }): DeepPartial<AccountSummaryPetHydrated> {
    const { agencyName, insuredEntity } = params

    if (!insuredEntity.uuid) {
      throw new Error('Insured entity uuid is required')
    }

    const basePet: Partial<AccountSummaryPetHydrated> = {
      id: toShortUuid(insuredEntity.uuid, 'pet'),
      name: insuredEntity.pet_name,
      breedLabel: insuredEntity.breed?.name,
      breed: insuredEntity.breed?.value,
      dateOfBirth: insuredEntity.dob,
      postcode: insuredEntity.pet_address_postcode || insuredEntity.zip,
      spayedNeutered: insuredEntity.spayed_neutered,
    }

    switch (insuredEntity.species) {
      case 'DOG':
        basePet.species = 'dog'
        break
      case 'CAT':
        basePet.species = 'cat'
        break
    }

    switch (insuredEntity.gender) {
      case 'MALE':
        basePet.gender = 'male'
        break
      case 'FEMALE':
        basePet.gender = 'female'
        break
    }

    switch (insuredEntity.pedigree_type) {
      case 'PEDIGREE':
        basePet.pedigreeType = 'pedigree'
        break
      case 'MIXED_BREED':
        basePet.pedigreeType = 'mixed'
        break
      case 'CROSS_BREED':
        basePet.pedigreeType = 'cross'
        break
    }

    switch (agencyName) {
      case 'MP-US':
        basePet.state = insuredEntity.state
        break
      case 'BBM':
        basePet.value = insuredEntity.value
        break
      case 'BBM-SE':
        basePet.registrationNumber = insuredEntity.registrationNumber
        break
    }

    return basePet
  }

  public static accountSummaryCustomerHydratedEnhancedWithBasketChanges(params: {
    customer: AccountSummaryCustomerHydrated
    change: BasketCustomerChange
  }): AccountSummaryCustomerHydrated {
    const { customer, change } = params
    const changedHydratedCustomer = cloneDeep(customer)

    if ('title' in change.data) {
      changedHydratedCustomer.title = change.data['title']
    }

    if ('first_name' in change.data) {
      changedHydratedCustomer.firstName = change.data['first_name']
    }

    if ('last_name' in change.data) {
      changedHydratedCustomer.lastName = change.data['last_name']
    }

    if (change.data['email']) {
      // there should never be a falsy value for email, but just in case
      // we don't want to overwrite a valid email with an empty string
      changedHydratedCustomer.email = change.data['email']
    }

    if ('telephone' in change.data) {
      changedHydratedCustomer.phone = change.data['telephone']
    }

    if ('date_of_birth' in change.data) {
      changedHydratedCustomer.dateOfBirth = change.data['date_of_birth']
    }

    if ('swedish_identity_number' in change.data) {
      changedHydratedCustomer.ssn = change.data['swedish_identity_number']
    }

    if ('address/city' in change.data) {
      changedHydratedCustomer.address!.city = change.data['address/city']
    }
    if ('address/country' in change.data) {
      changedHydratedCustomer.address!.country = change.data['address/country']
    }

    if ('address/county' in change.data) {
      changedHydratedCustomer.address!.stateOrCounty =
        change.data['address/county']
    }

    if ('address/postcode' in change.data) {
      changedHydratedCustomer.address!.postcode =
        change.data['address/postcode']
    }

    if ('address/line1' in change.data) {
      changedHydratedCustomer.address!.line1 = change.data['address/line1']
    }

    if ('address/line2' in change.data) {
      changedHydratedCustomer.address!.line2 = change.data['address/line2']
    }

    return changedHydratedCustomer
  }
}
