import {
  AccountSummary,
  AccountSummaryAddress,
  AccountSummaryCustomer,
  AccountSummaryDocuments,
  AccountSummaryPayment,
  PaymentSchedule,
  AccountSummaryPet,
  Title,
} from '@/domain/Account/AccountSummary'
import { PaymentMethodDetails } from '@/domain/Shop/PaymentMethodDetails'

import PetParser from '../Shop/PetParser'
import ProductPriceParser from '../Shop/ProductPriceParser'
import type { ProductTotalPriceJson } from '../Shop/ProductPriceParser'

import AccountSummaryItemParser from './AccountSummaryItemParser'

export type DocumentTypeJson =
  | 'certificate'
  | 'ipid_value'
  | 'ipid_regular'
  | 'ipid_complete'
  | 'ipid_travel'
  | 'ipid_loss_theft'
  | 'ipid_moneyback'
  | 'handbook'

export interface AccountSummaryJson {
  customer: CustomerJson
  pets: PetJson[]
  payment: PaymentJson
  items: ItemJson[]
  price: ProductTotalPriceJson
}

export interface CustomerJson {
  id: string
  email: string
  title: string
  address?: AddressJson
  phone?: number
  first_name?: string
  last_name?: string
  dob?: string
}

export interface PetJson {
  id: string
  display_name: string
  dob: string
  species: string
  gender: string
  breed: PetBreedJson
  postcode: string
  pedigree_type?: string
  spayed_neutered?: boolean
  state?: string
  value?: number
  cerf_score?: number | null
}

interface PaymentMethodDirectDebitJson {
  type: 'direct_debit'
  account_number_ending: string
  sort_code_ending: string
  account_holder_name: string
}

interface PaymentMethodCardJson {
  type: 'card'
  card_number_ending: string
  expiry_date: string
  card_brand: string
}

export type PaymentMethodJson =
  | PaymentMethodDirectDebitJson
  | PaymentMethodCardJson

export interface PaymentJson {
  day: number
  schedule: PaymentScheduleJson[]
  payment_method?: PaymentMethodJson
  status: {
    in_arrears: boolean
  }
  collect_on_payment_day: boolean
}

export interface ItemJson {
  affiliate_code: Nullable<string>
  id: string
  ref: string
  type: string
  product_name: string
  pet: string
  policy: PolicyJson
  product: ProductJson
  options: OptionJson[]
  price: PriceJson
  start_date: string
  end_date: string
  moneyback?: {
    expected_amount: number
  }
}

export interface PetBreedJson {
  display_name: string
  value: string
}

export interface AddressJson {
  line1: string
  line2?: string
  city: string
  state_county: string
  country: string
  postcode: string
}

export interface PaymentScheduleJson {
  due: string
  total: number
  type: 'regular' | 'adjustment' | 'combined'
  cover_period: Nullable<{
    start_date: string
    end_date: string
  }>
}

export interface PolicyJson {
  status: 'PRE_INCEPTION' | 'ON_RISK' | 'CANCELLED' | 'VOID'
  cooling_off: boolean
  accident_waiting_period_end_date?: string | null
  term_number: number
  co_payment?: number
  excess?: number
  limit?: number
  x_policy_uuid?: string
}

export interface OptionJson {
  sku: string
  price: PriceJson
}

export interface ProductJson {
  sku: string
  price: PriceJson
}

export interface PriceItemJson {
  subtotal: number
  discount: number
  total: number
  tax: number
}

export interface PriceJson {
  monthly_total: PriceItemJson
  term_total: PriceItemJson
  surcharge?: string[]
}

export interface DocumentJson {
  id: DocumentTypeJson
  type: 'ipid' | 'handbook'
  url: string
}

export interface AccountSummaryDocumentsJson {
  documents: {
    [key: string]: DocumentJson[]
  }
}

export default class AccountParser {
  public static parse(data: AccountSummaryJson): AccountSummary {
    return {
      customer: AccountParser.parseCustomer(data.customer),
      pets: data.pets.map((pet) => AccountParser.parsePet(pet, data.items)),
      payment: data.payment
        ? AccountParser.parsePayment(data.payment)
        : undefined,
      price: ProductPriceParser.parseRemainingTotalPrice(data.price),
    }
  }

  public static parsePet(data: PetJson, items: ItemJson[]): AccountSummaryPet {
    const petItems = items.filter((item) => item.pet === data.id)

    return {
      id: data.id,
      name: data.display_name,
      dateOfBirth: data.dob,
      species: PetParser.parseSpecies(data.species),
      gender: PetParser.parsePetGender(data.gender),
      pedigreeType: data.pedigree_type
        ? PetParser.parsePedigreeType(data.pedigree_type)
        : undefined,
      breed: data.breed.value,
      breedLabel: data.breed.display_name,
      postcode: data.postcode,
      spayedNeutered: data.spayed_neutered,
      items: petItems.map(AccountSummaryItemParser.parse),
      state: data.state,
      value: data.value,
      cerfScore: data.cerf_score,
    }
  }

  public static parsePayment(data: PaymentJson): AccountSummaryPayment {
    return {
      day: data.day,
      schedule: data.schedule.map(AccountParser.parsePaymentSchedule),
      inArrears: data.status?.in_arrears ?? false,
      paymentMethodDetails: AccountParser.parsePaymentDetails(
        data.payment_method
      ),
      collectOnPaymentDay: data.collect_on_payment_day ?? false,
    }
  }

  public static parsePaymentDetails(
    data: Maybe<PaymentMethodJson>
  ): Nullable<PaymentMethodDetails> {
    if (data?.type === 'card') {
      return {
        type: 'card',
        number: data.card_number_ending,
      }
    }
    if (data?.type === 'direct_debit') {
      return {
        type: 'direct_debit',
        accountNumber: data.account_number_ending,
        sortCode: data.sort_code_ending,
        accountHolderName: data.account_holder_name,
      }
    }

    return null
  }

  public static parsePaymentSchedule(
    data: PaymentScheduleJson
  ): PaymentSchedule {
    return {
      due: data.due,
      total: data.total,
      type: data.type,
      coverPeriod: data.cover_period
        ? {
            startDate: data.cover_period.start_date,
            endDate: data.cover_period.end_date,
          }
        : null,
    }
  }

  public static parseCustomer(data: CustomerJson): AccountSummaryCustomer {
    return {
      id: data.id,
      email: data.email,
      title: data.title as Title,
      firstName: data.first_name,
      lastName: data.last_name,
      address: data.address ? AccountParser.parseAddress(data.address) : {},
      phone: `${data.phone ?? ''}`,
      dateOfBirth: data.dob,
    }
  }

  public static parseAddress(data: AddressJson): AccountSummaryAddress {
    return {
      line1: data.line1,
      line2: data.line2,
      city: data.city,
      country: data.country,
      postcode: data.postcode,
      stateOrCounty: data.state_county,
    }
  }

  public static parseDocuments(
    data: AccountSummaryDocumentsJson
  ): AccountSummaryDocuments {
    const documents: AccountSummaryDocuments = {
      documents: [],
    }

    for (const key of Object.keys(data.documents)) {
      const itemIdDocuments = data.documents[key] ?? []
      itemIdDocuments.forEach((document) => {
        documents.documents.push({
          itemId: key,
          id: document.id,
          type: document.type,
          url: document.url,
        })
      })
    }

    return documents
  }
}
