<template>
  <div class="intro layout__intro">
    <div :class="['intro__icon', iconClass]" :style="iconStyle" />
    <div class="intro__contents">
      <slot name="title">
        <h1 v-if="heading" class="intro__heading">{{ heading }}</h1>
      </slot>
      <p v-if="description" class="intro__desc">{{ description }}</p>
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Intro',
  props: {
    description: {
      type: String,
      required: false,
      default() {
        return null
      },
    },
    heading: {
      type: String,
      required: false,
      default: undefined,
    },
    icon: {
      type: String,
      required: false,
      default: undefined,
    },
    photo: {
      type: String,
      required: false,
      default: undefined,
    },
    hint: {
      type: String,
      required: false,
      default: undefined,
    },
  },
  computed: {
    iconStyle() {
      return this.photo ? { backgroundImage: `url('${this.photo}')` } : null
    },
    iconClass() {
      return this.icon && `intro__icon--${this.icon}`
    },
  },
}
</script>
