import { AgencyName, ProductLineName } from '@/@types/Config'
import {
  AccountSummaryAddress,
  AccountSummaryCustomerHydrated,
  AccountSummaryPetHydrated,
} from '@/domain/Account/AccountSummary'
import ErrorResponseFactory from '@/domain/Error/ErrorResponseFactory'
import type { Order } from '@/domain/Order'
import { AvailableProductsBase } from '@/domain/Shop/AvailableProducts'

import { ShopCompatibilityModeApiService, ShopApiService } from '../ApiService'
import AvailableProductsParser from '../parsers/Shop/AvailableProductsParser'
import { ItemType, ItemTypeParser } from '../parsers/Shop/ItemTypeParser'
import OrderDto from '../parsers/Shop/OrderDto'
import { OrderCustomerNewJson, OrderParser } from '../parsers/Shop/OrderParser'
import { ItemTypeJson } from '../parsers/Shop/ProductCatalogParser'

export interface OrderStatusResponse {
  id: string
  status: 'processing' | 'failed' | 'completed'
  retry: number | null
}

export interface CreateNewNBOrderParams {
  agencyName: AgencyName
  customerEmail: string
  customerDateOfBirth: Maybe<string>
  customerAddress: Maybe<AccountSummaryAddress>
  pets: Omit<AccountSummaryPetHydrated, 'items' | 'id' | 'age'>[]
  effectiveDate: string
  product_line_key?: Maybe<ProductLineName>
  affiliate_code?: Maybe<string>
}

export default class ShopService {
  public static async getOrder({
    orderId,
  }: {
    orderId: string
  }): Promise<Order> {
    try {
      return await ShopCompatibilityModeApiService.get({
        path: `/v2/orders/${orderId}`,
        parse: OrderParser.parse,
      })
    } catch (error) {
      const errorClass = await ErrorResponseFactory.instantiateErrorClass({
        title: 'Unable to get order',
        originalError: error,
        tags: ['Shop'],
      })
      throw errorClass
    }
  }

  public static async getOrderForBasketV2({
    basketId,
  }: {
    basketId: string
  }): Promise<Order> {
    const orderId = basketId.replace('bkt_', 'ord_')

    try {
      return await ShopApiService.get({
        path: `/v2/orders/${orderId}`,
        parse: OrderParser.parse,
      })
    } catch (error) {
      const errorClass = await ErrorResponseFactory.instantiateErrorClass({
        title: 'Unable to get order for basket v2',
        originalError: error,
        tags: ['Shop'],
      })
      throw errorClass
    }
  }

  public static async getOrderByBasketId({
    basketId,
  }: {
    basketId: string
  }): Promise<Order> {
    try {
      return await ShopService.getOrder({
        orderId: basketId.replace('bas_', 'ord_'),
      })
    } catch (error) {
      const errorClass = await ErrorResponseFactory.instantiateErrorClass({
        title: 'Unable to get order by basket id',
        originalError: error,
        tags: ['Shop'],
      })
      throw errorClass
    }
  }

  public static async purchaseOrder({
    orderId,
    paymentToken,
  }: {
    orderId: string
    paymentToken?: Nullable<string>
  }): Promise<void> {
    try {
      if (paymentToken) {
        await ShopCompatibilityModeApiService.post({
          path: `/v2/orders/${orderId}/purchase`,
          params: {
            json: {
              payment: {
                token: paymentToken,
              },
            },
          },
        })
      } else {
        await ShopCompatibilityModeApiService.post({
          path: `/v2/orders/${orderId}/purchase`,
        })
      }
    } catch (error) {
      const errorClass = await ErrorResponseFactory.instantiateErrorClass({
        title: 'Unable to purchase order',
        originalError: error,
        tags: ['Shop'],
      })

      throw errorClass
    }
  }

  public static async purchaseOrderFromV2Basket({
    orderId,
    paymentToken,
  }: {
    orderId: string
    paymentToken?: string
  }): Promise<void> {
    try {
      if (paymentToken) {
        await ShopApiService.post({
          path: `/v2/orders/${orderId}/purchase`,
          params: {
            json: {
              payment: {
                token: paymentToken,
              },
            },
          },
        })
      } else {
        await ShopApiService.post({
          path: `/v2/orders/${orderId}/purchase`,
        })
      }
    } catch (error) {
      const errorClass = await ErrorResponseFactory.instantiateErrorClass({
        title: 'Unable to purchase order from v2 basket',
        originalError: error,
        tags: ['Shop'],
      })
      throw errorClass
    }
  }

  public static async selectProductsForPetLegacy({
    orderId,
    petId,
    products,
  }: {
    orderId: string
    petId: string
    products: Array<{
      compulsory: string[]
      options: string[]
    }>
  }): Promise<Order> {
    try {
      return await ShopCompatibilityModeApiService.post({
        path: `/v2/orders/${orderId}/pets/${petId}/products/selected`,
        params: {
          json: {
            products,
          },
        },
        parse: OrderParser.parse,
      })
    } catch (error) {
      const errorClass = await ErrorResponseFactory.instantiateErrorClass({
        title: 'Unable to select products for pet legacy',
        originalError: error,
        tags: ['Shop'],
      })
      throw errorClass
    }
  }

  public static async selectProductsForPet({
    orderId,
    petId,
    products,
  }: {
    orderId: string
    petId: string
    products: Array<{
      sku: string
      options: string[]
    }>
  }): Promise<Order> {
    try {
      return await ShopCompatibilityModeApiService.post({
        path: `/v2/orders/${orderId}/pets/${petId}/products/selected`,
        params: {
          json: {
            products,
          },
        },
        parse: OrderParser.parse,
      })
    } catch (error) {
      const errorClass = await ErrorResponseFactory.instantiateErrorClass({
        title: 'Unable to select products for pet',
        originalError: error,
        tags: ['Shop'],
      })
      throw errorClass
    }
  }

  public static async selectProductsForPetBasketV2({
    orderId,
    petId,
    products,
  }: {
    orderId: string
    petId: string
    products: Array<{
      sku: string
      options: string[]
    }>
  }): Promise<Order> {
    try {
      return await ShopApiService.post({
        path: `/v2/orders/${orderId}/pets/${petId}/products/selected`,
        params: {
          json: {
            products,
          },
        },
        parse: OrderParser.parse,
      })
    } catch (error) {
      const errorClass = await ErrorResponseFactory.instantiateErrorClass({
        title: 'Unable to select products for pet basket v2',
        originalError: error,
        tags: ['Shop'],
      })
      throw errorClass
    }
  }

  public static async getProductsForPet(params: {
    orderId: string
    petId: string
  }): Promise<AvailableProductsBase> {
    const { orderId, petId } = params

    try {
      return await ShopCompatibilityModeApiService.get({
        path: `/v2/orders/${orderId}/pets/${petId}/products/available`,
        params: {
          searchParams: {
            include_pre_existing: 'true',
            product_type: 'all',
            enable_discounts: 'true',
          },
        },
        parse: AvailableProductsParser.parse,
      })
    } catch (error) {
      const errorClass = await ErrorResponseFactory.instantiateErrorClass({
        title: 'Unable to get products for pet',
        originalError: error,
        tags: ['Shop'],
      })
      throw errorClass
    }
  }

  public static async getProductsForPetV2Basket(params: {
    orderId: string
    petId: string
  }): Promise<AvailableProductsBase> {
    const { orderId, petId } = params

    try {
      return await ShopApiService.get({
        path: `/v2/orders/${orderId}/pets/${petId}/products/available`,
        params: {
          searchParams: {
            include_pre_existing: 'true',
            product_type: 'all',
            enable_discounts: 'true',
          },
        },
        parse: AvailableProductsParser.parse,
      })
    } catch (error) {
      const errorClass = await ErrorResponseFactory.instantiateErrorClass({
        title: 'Unable to get products for pet v2 basket',
        originalError: error,
        tags: ['Shop'],
      })
      throw errorClass
    }
  }

  public static async isCustomerSgp(params: {
    customerId?: string
    customerEmail?: string
  }): Promise<boolean> {
    const { customerId, customerEmail } = params
    const searchParams: any = {}

    // Check if neither of the properties is set and throw an error
    if (!customerId && !customerEmail) {
      throw new Error('Either customerId or customerEmail must be set.')
    }

    if (customerId) {
      searchParams.customer_id = customerId
    } else if (customerEmail) {
      searchParams.email = customerEmail
    }

    try {
      return await ShopApiService.get({
        path: `/v2/validate-email-sgp`,
        params: {
          searchParams,
        },
        parse: (data: { use_sgp: null | boolean }) => {
          // if customer has 0 policies we want to allow SGP.
          if (data.use_sgp === null) {
            return true
          }

          return data.use_sgp
        },
      })
    } catch (error) {
      const errorClass = await ErrorResponseFactory.instantiateErrorClass({
        title: 'Unable to check if customer is SGP',
        originalError: error,
        tags: ['Shop'],
      })
      throw errorClass
    }
  }

  public static async createCancellationOrder(params: {
    customerId: string
    items: {
      pet: string
      type: ItemTypeJson
      reason: string
      action: 'remove'
    }[]
    effectiveDate: string
  }): Promise<Order> {
    const { customerId, items, effectiveDate } = params

    try {
      return await ShopCompatibilityModeApiService.post({
        path: `/v2/orders`,
        params: {
          json: {
            customer: {
              id: customerId,
            },
            items,
            effective_date: effectiveDate,
          },
        },
        parse: OrderParser.parse,
      })
    } catch (error) {
      const errorClass = await ErrorResponseFactory.instantiateErrorClass({
        title: 'Unable to create cancellation order',
        originalError: error,
        tags: ['Shop'],
        disableAutoLog: true,
      })

      throw errorClass
    }
  }

  public static async createAdjustItemOrder(params: {
    customerId: string
    items: {
      pet: string
      type: ItemTypeJson
      action: 'adjust'
    }[]
    effectiveDate: string
  }): Promise<Order> {
    const { customerId, items, effectiveDate } = params

    try {
      return await ShopCompatibilityModeApiService.post({
        path: `/v2/orders`,
        params: {
          json: {
            customer: {
              id: customerId,
            },
            items,
            effective_date: effectiveDate,
          },
        },
        parse: OrderParser.parse,
      })
    } catch (error) {
      const errorClass = await ErrorResponseFactory.instantiateErrorClass({
        title: 'Unable to create adjust product order',
        originalError: error,
        tags: ['Shop'],
        disableAutoLog: true,
      })

      throw errorClass
    }
  }

  public static async changePaymentDateOrder(params: {
    basketVersion: 1 | 2
    customerId: string
    paymentDay: number
    effectiveDate: string
  }): Promise<Order> {
    const { customerId, paymentDay, effectiveDate, basketVersion } = params
    const apiService =
      basketVersion === 1 ? ShopCompatibilityModeApiService : ShopApiService
    try {
      return await apiService.post({
        path: `/v2/orders`,
        params: {
          json: {
            subscription: {
              payment_day: paymentDay,
            },
            customer: {
              id: customerId,
            },
            effective_date: effectiveDate,
          },
        },
        parse: OrderParser.parse,
      })
    } catch (error) {
      const errorClass = await ErrorResponseFactory.instantiateErrorClass({
        title: 'Unable to create order for changing payment date',
        originalError: error,
        tags: ['Shop'],
        disableAutoLog: true,
      })

      throw errorClass
    }
  }

  public static async updateCancellationOrder(params: {
    basketVersion: 1 | 2
    orderId: string
    pet: string
    reason: string
    type: ItemTypeJson
    action: 'remove'
  }): Promise<Order> {
    const { basketVersion, orderId, pet, reason, type, action } = params
    const apiService =
      basketVersion === 1 ? ShopCompatibilityModeApiService : ShopApiService

    try {
      return await apiService.post({
        path: `/v2/orders/${orderId}/items`,
        params: {
          json: {
            pet,
            type,
            reason,
            action,
          },
        },
        parse: OrderParser.parse,
      })
    } catch (error) {
      const errorClass = await ErrorResponseFactory.instantiateErrorClass({
        title: 'Unable to update cancellation order',
        originalError: error,
        tags: ['Shop'],
        disableAutoLog: true,
      })

      throw errorClass
    }
  }

  public static async updateEffectiveDate(params: {
    orderId: string
    effectiveDate: string
    basketVersion: 1 | 2
  }): Promise<Order> {
    const apiService =
      params.basketVersion === 1
        ? ShopCompatibilityModeApiService
        : ShopApiService

    try {
      return await apiService.post({
        path: `/v2/orders/${params.orderId}/effective-date`,
        params: {
          json: {
            effective_date: params.effectiveDate,
          },
        },
        parse: OrderParser.parse,
      })
    } catch (error) {
      const errorClass = await ErrorResponseFactory.instantiateErrorClass({
        title: 'Unable to update effective date',
        originalError: error,
        tags: ['Shop'],
      })
      throw errorClass
    }
  }

  public static async createNewNBOrder(
    params: CreateNewNBOrderParams
  ): Promise<Order> {
    const petsJson = OrderDto.toCreateAddPetOrder(
      params.agencyName,
      params.pets
    )

    const customerJson: OrderCustomerNewJson = {
      email: params.customerEmail,
    }

    if (params.customerDateOfBirth) {
      customerJson.dob = params.customerDateOfBirth
    }

    if (params.customerAddress) {
      customerJson.address = {
        line1: params.customerAddress.line1,
        line2: params.customerAddress.line2,
        city: params.customerAddress.city,
        postcode: params.customerAddress.postcode,
        country: params.customerAddress.country,
        state_county: params.customerAddress.stateOrCounty,
      }
    }

    const json: any = {
      customer: customerJson,
      pets: petsJson,
      effective_date: params.effectiveDate,
      affiliate_code: params.affiliate_code,
    }

    if (params.product_line_key && params.product_line_key === 'cat-dog-pio') {
      json.product_line_key = params.product_line_key
    }

    try {
      return await ShopApiService.post({
        path: `/v2/orders`,
        params: {
          json,
        },
        parse: OrderParser.parse,
      })
    } catch (error) {
      const errorClass = await ErrorResponseFactory.instantiateErrorClass({
        title: 'Unable to create new NB order',
        originalError: error,
        tags: ['Shop'],
      })
      throw errorClass
    }
  }

  public static async createAddPetOrder(params: {
    agencyName: AgencyName
    customerId: string
    pets: AccountSummaryPetHydrated[]
    effectiveDate: string
    product_line_key: string
  }): Promise<Order> {
    const petsJson = OrderDto.toCreateAddPetOrder(
      params.agencyName,
      params.pets
    )

    const json: any = {
      customer: {
        id: params.customerId,
      },
      pets: petsJson,
      effective_date: params.effectiveDate,
    }

    if (params.product_line_key && params.product_line_key === 'cat-dog-pio') {
      json.product_line_key = params.product_line_key
    }

    try {
      return await ShopApiService.post({
        path: `/v2/orders`,
        params: {
          json,
        },
        parse: OrderParser.parse,
      })
    } catch (error) {
      const errorClass = await ErrorResponseFactory.instantiateErrorClass({
        title: 'Unable to create add pet order',
        originalError: error,
        tags: ['Shop'],
      })
      throw errorClass
    }
  }

  public static async addPetToOrder(params: {
    agencyName: AgencyName
    orderId: string
    pet: Omit<AccountSummaryPetHydrated, 'items' | 'id' | 'age'>
  }): Promise<Order> {
    const orderPet = OrderDto.toCreateAddPetOrderPet(
      params.agencyName,
      params.pet
    )

    try {
      return await ShopCompatibilityModeApiService.post({
        path: `/v2/orders/${params.orderId}/pets`,
        params: {
          json: {
            ...orderPet,
          },
        },
        parse: OrderParser.parse,
      })
    } catch (error) {
      const errorClass = await ErrorResponseFactory.instantiateErrorClass({
        title: 'Unable to add pet to order',
        originalError: error,
        tags: ['Shop'],
      })
      throw errorClass
    }
  }

  public static async removePetFromOrder(params: {
    orderId: string
    petId: string
  }): Promise<Order> {
    const { orderId, petId } = params

    try {
      return await ShopCompatibilityModeApiService.post({
        path: `/v2/orders/${orderId}/pets/${petId}/remove`,
        parse: OrderParser.parse,
      })
    } catch (error) {
      const errorClass = await ErrorResponseFactory.instantiateErrorClass({
        title: 'Unable to remove pet from order',
        originalError: error,
        tags: ['Shop'],
      })
      throw errorClass
    }
  }

  public static async beginPaymentCapture(params: {
    orderId: string
    paymentService: 'gocardless' | 'stripe'
    customer: AccountSummaryCustomerHydrated
  }): Promise<string> {
    const { orderId, customer, paymentService } = params
    const billing_customer = OrderDto.toBeginPaymentMethodCapture(customer)

    return ShopCompatibilityModeApiService.post({
      path: `/v2/orders/${orderId}/begin-payment-method-capture`,
      params: {
        json: {
          billing_customer,
          payment_service: paymentService,
        },
      },
      parse: (data: any) => data.payment_flow_id,
    })
  }

  public static async getOrderStatus(params: {
    orderId: string
  }): Promise<OrderStatusResponse | null> {
    try {
      const response = await ShopCompatibilityModeApiService.get({
        path: `/v2/orders/${params.orderId}/purchase/status`,
        parse: (data: any) => data,
      })

      if (response.status === 'completed' || response.status === 'failed') {
        return response
      } else if (response.status === 'processing' && response.retry !== null) {
        await new Promise((resolve) =>
          setTimeout(resolve, response.retry * 1000)
        )
        return ShopService.getOrderStatus(params)
      } else {
        //if we don't get a retry interval just retry after 5 seconds
        await new Promise((resolve) => setTimeout(resolve, 5000))

        return ShopService.getOrderStatus(params)
      }
    } catch (error) {
      const errorClass = await ErrorResponseFactory.instantiateErrorClass({
        title: 'Unable to retrive order status',
        originalError: error,
        tags: ['Shop'],
      })
      throw errorClass
    }
  }

  public static async reinstatePolicies({
    customer_id,
    policies,
  }: {
    customer_id: string
    policies: string[]
  }): Promise<string> {
    try {
      return await ShopCompatibilityModeApiService.post({
        path: `/v2/reinstate`,
        params: {
          json: {
            customer_id,
            policies,
          },
        },
        parse: (data: any) => data.order_id,
      })
    } catch (error) {
      const errorClass = await ErrorResponseFactory.instantiateErrorClass({
        title: 'Unable to reinstate policies',
        originalError: error,
        tags: ['Shop'],
      })
      throw errorClass
    }
  }

  public static async updateEditCustomer(params: {
    basketVersion: 1 | 2
    customer: Partial<AccountSummaryCustomerHydrated>
    orderId: string
  }): Promise<Order> {
    const { customer, orderId } = params

    const service =
      params.basketVersion === 1
        ? ShopCompatibilityModeApiService
        : ShopApiService

    try {
      const orderCustomer = OrderDto.toOrderNewBusinessCustomer(customer)
      const newOrder = await service.post({
        path: `/v2/orders/${orderId}/customer/update`,
        params: {
          json: orderCustomer,
        },
        parse: OrderParser.parse,
      })

      return newOrder
    } catch (error) {
      const errorClass = await ErrorResponseFactory.instantiateErrorClass({
        title: 'Unable to add customer details to order',
        originalError: error,
        tags: ['Shop'],
      })
      throw errorClass
    }
  }

  public static async removeItemFromorder(params: {
    basketVersion: 1 | 2
    orderId: string

    items: {
      pet: string
      type: ItemType
    }[]
  }): Promise<Order> {
    const { orderId } = params

    try {
      const service =
        params.basketVersion === 1
          ? ShopCompatibilityModeApiService
          : ShopApiService

      const items = params.items.map((item) => ({
        pet: item.pet,
        type: ItemTypeParser.serialise(item.type),
      }))

      return await service.post({
        path: `/v2/orders/${orderId}/items/remove`,
        params: {
          json: {
            items,
          },
        },
        parse: OrderParser.parse,
      })
    } catch (error) {
      const errorClass = await ErrorResponseFactory.instantiateErrorClass({
        title: 'Unable to remove item',
        originalError: error,
        tags: ['Shop'],
      })
      throw errorClass
    }
  }

  public static async updateEditPet(params: {
    basketVersion: 1 | 2
    pet: AccountSummaryPetHydrated
    orderId: string
  }): Promise<Order> {
    const { pet, orderId } = params

    // todo basket v1
    const service =
      params.basketVersion === 1
        ? ShopCompatibilityModeApiService
        : ShopApiService

    try {
      const orderPet = OrderDto.toOrderNewBusinessPet(pet)
      const newOrder = await service.post({
        path: `/v2/orders/${orderId}/pets/${pet.id}/update`,
        params: {
          json: orderPet,
        },
        parse: OrderParser.parse,
      })

      return newOrder
    } catch (error) {
      const errorClass = await ErrorResponseFactory.instantiateErrorClass({
        title: 'Unable to edit pet details',
        originalError: error,
        tags: ['Shop'],
      })
      throw errorClass
    }
  }

  public static async updatePaymentDataOnOrder(params: {
    basketVersion: 1 | 2
    orderId: string
    paymentDay: number
    paymentMethod: 'direct_debit' | 'card' | undefined
  }): Promise<Order> {
    const { paymentDay, orderId } = params
    const service =
      params.basketVersion === 1
        ? ShopCompatibilityModeApiService
        : ShopApiService

    const json: {
      payment_day: string
      payment_method?: 'direct_debit' | 'card'
    } = {
      payment_day: String(paymentDay),
    }

    if (params.paymentMethod) {
      json.payment_method = params.paymentMethod
    }

    try {
      const newOrder = await service.post({
        path: `/v2/orders/${orderId}/payment`,
        params: {
          json,
        },
        parse: OrderParser.parse,
      })

      return newOrder
    } catch (error) {
      const errorClass = await ErrorResponseFactory.instantiateErrorClass({
        title: 'Unable to update payment data',
        originalError: error,
        tags: ['Shop'],
      })
      throw errorClass
    }
  }
}
