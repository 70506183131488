<template>
  <ul class="mutt-forms-dropdown">
    <!-- The "select" drop down -->
    <li
      ref="selectBox"
      class="mutt-forms-dropdown__selected-option"
      role="button"
      tabindex="0"
      aria-haspopup="listbox"
      :aria-expanded="dropdownOpen"
      @click="toggleDropdown"
      @keydown.esc="closeDropdown"
      @keydown.space="toggleDropdown"
      @keydown.enter="toggleDropdown"
      @keydown.up="focusNextListItem(UP)"
      @keydown.down="focusNextListItem(DOWN)"
    >
      {{ (selectedOption && selectedOption.label) || emptyLabel }}

      <!-- Icon -->
      <svg
        :class="{
          'mutt-forms-dropdown__arrow': true,
          'mutt-forms-dropdown__arrow--expanded': dropdownOpen,
        }"
        width="40"
        height="40"
        preserveAspectRatio="xMidYMid meet"
        viewBox="0 0 50 50"
        fill-rule="evenodd"
        focusable="false"
      >
        <title>Open drop down</title>
        <path
          d="M25 32.4l-9.7-9.7l1.4-1.4l8.3 8.3l8.3-8.3l1.4 1.4z"
          fill="#626262"
        />
      </svg>
    </li>

    <li class="mutt-forms-dropdown__list-container" role="list" tabindex="-1">
      <ul
        :class="{
          'mutt-forms-dropdown__list': true,
          'mutt-forms-dropdown__list--open': dropdownOpen,
        }"
      >
        <!-- eslint-disable-next-line vuejs-accessibility/interactive-supports-focus -->
        <li
          v-for="(option, idx) in options"
          :key="option.value"
          :ref="'option-' + idx"
          :tabindex="dropdownOpen ? 0 : null"
          :class="{
            'mutt-forms-dropdown__list-item': true,
            'mutt-forms-dropdown__list-item--selected':
              selectedOption && option.value === selectedOption.value,
          }"
          :value="option.value"
          role="option"
          :aria-selected="
            selectedOption && option.value === selectedOption.value
          "
          @click="selectOption(option)"
          @keydown.enter="selectOption(option)"
          @keydown.space="selectOption(option)"
          @keydown.tab="selectOption(option)"
          @keydown.esc="toggleDropdown"
          @keydown.up="focusNextListItem(UP)"
          @keydown.down="focusNextListItem(DOWN)"
        >
          {{ option.label }}
        </li>
      </ul>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'Dropdown',
  props: {
    value: {
      type: [String, Number],
      default: null,
    },
    emptyLabel: {
      type: String,
      default: 'Select a value',
    },
    options: {
      type: Array,
      required: true,
      validator: (prop) => {
        return prop.every((propValue) => {
          return (
            propValue instanceof Object &&
            Object.prototype.hasOwnProperty.call(propValue, 'value') &&
            Object.prototype.hasOwnProperty.call(propValue, 'label')
          )
        })
      },
    },
  },
  emits: ['input'],
  data() {
    return {
      dropdownOpen: false,
      selectedOption: null,
      UP: 'UP',
      DOWN: 'DOWN',
    }
  },
  watch: {
    value(value) {
      this.selectedOption = this.options.find(
        (option) => option.value === value
      )
    },
  },
  mounted() {
    // Close the dropdown when anything not within the dropdown is clicked
    document.addEventListener('click', (event) => {
      if (!this.$el.contains(event.target)) {
        this.closeDropdown()
      }
    })
  },
  methods: {
    focus() {
      this.$refs.selectBox.focus()
    },
    toggleDropdown() {
      this.dropdownOpen = !this.dropdownOpen
    },
    closeDropdown() {
      this.dropdownOpen = false
    },
    selectOption(option) {
      this.selectedOption = option
      this.$emit('input', option.value)
      this.toggleDropdown()
    },
    async focusNextListItem(direction) {
      if (!this.dropdownOpen) {
        // list isn't open, open it
        this.toggleDropdown()
      }

      const activeElement = document.activeElement
      if (activeElement.className === 'mutt-forms-dropdown__selected-option') {
        // current focus is on the pseudo 'select', focus the first option
        await this.$nextTick()
        this.$refs['option-0']?.[0]?.focus()
      } else {
        // get the index of the focussed option using  it's position in the options array
        const currentActiveIndex = this.options.findIndex((opt) => {
          return opt.value === activeElement.value
        })
        if (currentActiveIndex === null || currentActiveIndex === undefined) {
          return
        }
        if (direction === this.DOWN) {
          // only move focus down an option if we aren't at the end of the list
          if (currentActiveIndex < this.options.length - 1) {
            const nextValueIdx = (currentActiveIndex || 0) + 1
            const nextElement = this.$refs[`option-${nextValueIdx}`][0]
            if (nextElement) {
              await this.$nextTick()
              nextElement.focus()
            }
          }
        } else if (direction === this.UP) {
          // only move focus up an option if we aren't at the start of the list
          if (currentActiveIndex > 0) {
            const nextValueIdx = (currentActiveIndex || this.options.length) - 1
            const nextElement = this.$refs[`option-${nextValueIdx}`][0]
            if (nextElement) {
              await this.$nextTick()
              nextElement.focus()
            }
          }
        }
      }
    },
  },
}
</script>

<style lang="css" scoped>
.mutt-forms-dropdown {
  list-style: none;
  position: relative;
  cursor: pointer;
}
.mutt-forms-dropdown__arrow {
  position: absolute;
  right: 0;
  top: 0;
  transition: transform 0.2s linear;
}
.mutt-forms-dropdown__arrow--expanded {
  -ms-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.mutt-forms-dropdown__list {
  z-index: 1000;
  width: 100%;
  position: absolute;
  left: 0;
  transition:
    opacity 0.1s cubic-bezier(0, 0, 0.38, 0.9),
    max-height 0.5s cubic-bezier(0, 0, 0.38, 0.9);
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  list-style: none;
}
.mutt-forms-dropdown__list--open {
  opacity: 1;
  overflow: auto;
  max-height: 15rem; /* Won't show at all without a height */
}
.mutt-forms-dropdown__list-container {
  position: relative;
}
</style>
